import Image from 'next/image';

import Consts from '../../../helpers/class/Consts'
import FooterMenu from '../menu/FooterMenu';
import LoggiaSVG from '../partials/LoggiaSVG';
import {useEffect} from "react";

export default function FooterWithApps ( props ) {

  return (
    <footer className={'footer p-t-40 mt-auto ' + (props.footerPaddingClass!=null?props.footerPaddingClass:'') } style={ props.fixed ? { paddingBottom: '7.625rem' } : {}}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <div className="row justify-content-center justify-content-lg-start">
              {
                props.iosApp != null ?
                  <div className="d-flex justify-content-center w-auto h-auto px-1">
                    <a className="h-auto w-auto" href={props.iosApp} title="iosApp">
                      <Image
                        layout={'fixed'}
                        width={135}
                        height={40}
                        src={Consts.AppleAppSvg}
                        alt="ios_app"
                        placeholder={'blur'}
                        blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                      />
                    </a>
                  </div>
                  :''
              }
              {
                props.androidApp != null ?
                  <div className="d-flex justify-content-center w-auto h-auto px-1">
                    <a className="h-auto w-auto" href={props.androidApp} title="androidApp">
                      <Image
                        layout={'fixed'}
                        width={135}
                        height={40}
                        src={Consts.AndroidAppSvg}
                        alt="android_app"
                        placeholder={'blur'}
                        blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                      />
                    </a>
                  </div>
                  :''
              }
            </div>
            <div className="row light-mode">
              <FooterMenu menu={props.menu} apps={true}/>
            </div>
          </div>
          {
            props.registrationNumberText != null ?
              <div className="d-flex col-12 col-lg-6 justify-content-center justify-content-lg-end align-self-end" style={{ marginBottom: '13px' }}>
                <p className="fs-11 text-master w-auto text-lg-end" style={{ textAlign: 'center' }}>
                  {props.registrationNumberText}<span className="fw-bold">{props.registrationNumber}</span>
                </p>
              </div>
              :''
          }
        </div>
      </div>
      <div className={'bg-design-color pt-3 pb-3\ ' + props.footerClass}>
        <div className="container">
          <div className="row">
            <div className="d-flex flex-column col-12 col-lg-6 align-items-center align-items-lg-start text-lg-start" style={{ textAlign: 'center' }}>
              {
                props.iosAppText != null ?
                  <p className="fs-11 no-margin">{props.iosAppText}</p>
                  :''
              }
              {
                props.androidAppText != null ?
                  <p className="fs-11 no-margin">{props.androidAppText}</p>
                  :''
              }
              {
                props.loggiaBadgeText != null ?
                  <p className="fs-11 no-margin">{props.loggiaBadgeText}</p>
                  :''
              }
            </div>
            <div id={'w3c_div'} className={'col-12 col-lg-6 justify-content-center justify-content-lg-end text-lg-end py-3 py-lg-0'} style={{ textAlign: 'center' }}>
              <p className={'fs-11'}>Copyright &copy; {props.title}. All rights reserved.</p>
              <a id={'w3c'} href={'https://validator.w3.org/nu/?doc=' + window.location} aria-labelledby={'w3c_div'} target="_blank" rel="noreferrer" title={'w3c'} style={{ width: 56, height: 20 }}>
                <Image
                  layout={'fixed'}
                  width={56}
                  height={20}
                  src={Consts.W3cValidatorSvg}
                  alt="w3c"
                />
              </a>
              <form id="ackecker_form" target="_blank" method="post"
                action="https://achecker.achecks.ca/checker/index.php" style={{ display: 'inline-block' }}
                className="ng-pristine ng-valid">
                <input type="hidden" name="uri" id="checkuri" value="window.location}"/>
                <a id={'wcag_form_submit'} href={'https://achecks.org/checker/index.php'} aria-labelledby={'ackecker_form'} target="_blank" rel="noreferrer" title={'wcag_form_submit'} style={{ width: 56, height: 20 }}>
                  <Image
                    layout={'fixed'}
                    width={56}
                    height={20}
                    src={Consts.WcagValidatorSvg}
                    alt="wcag"
                  />
                </a>
              </form>
            </div>
          </div>
          {
            props.hasLoggiaBadge ?
              <div className={'row justify-content-center text-center py-3'}>
                <a href="https://www.loggia.gr" target="_blank" aria-label="Made with Loggia's SimpleLodge"
                  title="Made with Loggia's SimpleLodge" rel="noreferrer" className="w-auto">
                  <LoggiaSVG/>
                </a>
              </div>
              :''
          }
        </div>
      </div>
    </footer>
  )
}
