import {useRouter} from "next/router";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../helpers/MethodHelper";
import { updateMainPacer } from "../../../redux/actions";

export const LoaderComponent = () => {

    const router = useRouter();

    const dispatch = useDispatch()

    const mainPacerHandler = (value : boolean) => {
        dispatch(updateMainPacer(value))
    }

    useEffect(() => {
        const handleStart = (url) => (url !== router.asPath) && mainPacerHandler(true);
        const handleComplete = (url) => (url === router.asPath) && mainPacerHandler(false);

        router.events.on('routeChangeStart', handleStart)
        router.events.on('routeChangeComplete', handleComplete)
        router.events.on('routeChangeError', handleComplete)

        return () => {
            router.events.off('routeChangeStart', handleStart)
            router.events.off('routeChangeComplete', handleComplete)
            router.events.off('routeChangeError', handleComplete)
        }
    })

    return (
        <>
        </>
    )
}