import React, {FormEvent, useEffect, useState} from 'react';

import {faSpinner} from '@fortawesome/pro-regular-svg-icons'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import Checkers from "../../../../helpers/class/Checkers";

import {sendNewsletterForm} from "../../../../api/client";
import {Alert} from "@mui/lab";
import {isDarkMode, textColor} from "../../../../helpers/MethodHelper";

interface NewsletterSectionProps {
  title?: string
  subtitle?: string
  description?: string
  newsletterAskFirstname?: boolean
  newsletterAskLastname?: boolean
  newsletterAskPhone?: boolean
  newsletterBgImage?: string
  newsletterBackground?: string
  darkMode?: boolean|number|string
}

export default function NewsletterSection ({
                                             title,
                                             subtitle,
                                             description,
                                             newsletterAskFirstname,
                                             newsletterAskLastname,
                                             newsletterAskPhone,
                                             newsletterBgImage,
                                             newsletterBackground,
                                             darkMode
                                           }: NewsletterSectionProps ) {

  const calculateColumns = () => {
    let columns = 0;
    if (newsletterAskFirstname) {
      columns++;
    }
    if (newsletterAskLastname) {
      columns++;
    }
    if (newsletterAskPhone) {
      columns++;
    }

    switch (columns) {
      case 1:
        return 'col-12'
      case 2:
        return 'col-6'
      case 3:
        return 'col-4'
      default:
        return null
    }
  }

  const pageId = useSelector(state => state['propertiesParams']['page_id']);

  const [formSubmitting, setFormSubmitting] = useState(false)

  const [showResponseMessage, setShowResponseMessage] = useState(false)

  const [formSuccess, setFormSuccess] = useState(null)

  const [notice, setNotice] = useState(false)

  const [responseMessage, setResponseMessage] = useState('')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormSubmitting(true)

    // const recaptchaValue = recaptchaRef.current.getValue();

    // if (recaptchaValue.length === 0) {
    //   setReCaptchaValiddation(2)
    //
    //   return;
    // } else {
    //   setReCaptchaValiddation(1)
    // }

    let data = new FormData(event.currentTarget)

    let formObject = Object.fromEntries(data.entries());

    let response = await sendNewsletterForm({'page_id': pageId}, formObject)

    setShowResponseMessage(true)

    if (response.success == 1) {
      setFormSuccess(true)
      if (response.notice == 1) {
        setNotice(true)
      }
    } else {
      setFormSuccess(false)
    }

    if (Checkers.isValidString(response.message)) {
      setResponseMessage(response.message)
    }
    setFormSubmitting(false)
  }

  const getNewsletterBackgroundColor = () => {
    if (darkMode === true || darkMode === "1") {
      return 'rgba(0, 0, 0)'
    }

    return 'rgba(255, 255, 255)'
  }

  return (
      <>
        {
          <>
            <section className="p-b-100 p-t-100 bg-master-darkest design_color"
                     data-pages-bg-image={newsletterBgImage!=null ? newsletterBgImage : ''}
                     data-pages-bg-webp={newsletterBgImage!=null ? newsletterBgImage : ''}
                     style={{
                       backgroundSize: 'cover',
                       backgroundPosition: 'center 37.1366%',
                       backgroundImage: 'url(' + (newsletterBgImage!=null ? newsletterBgImage : '') + ')'
                     }}
                     data-pages="parallax"
                     // data-bg-overlay="white" data-overlay-opacity="0.25"
            >
              <div className="background-overlay" style={{
                backgroundColor: (Checkers.isHexColor(newsletterBackground) ? newsletterBackground : getNewsletterBackgroundColor() ),
                opacity: Checkers.isHexColor(newsletterBackground) ? 1 : 0.5
              }}></div>
              <div className="container" style={{zIndex: 1}}>
                <div className="row justify-content-center">
                  <div className="col-sm-8">
                    <p className={"block-title m-b-0 text-master text-center " + textColor(darkMode)}>
                      {
                        Checkers.isValidString(title) ?
                            <>{title}</>
                            :
                            <>Stay in touch</>
                      }
                    </p>
                    <h2 className={textColor(darkMode) + " text-master text-center m-t-5"}>
                      {
                        Checkers.isValidString(subtitle) ?
                            <>{subtitle}</>
                            :
                            <>Sign up now and receive exclusive offers and benefits.</>
                      }
                    </h2>
                    <p className={"small p-t-0 text-master text-center " + textColor(darkMode)}>
                      {
                        Checkers.isValidString(subtitle) ?
                            <>{description}</>
                            :
                            <>We value your privacy. None of the details supplied will be shared with external parties</>
                      }
                      </p>

                      {
                        showResponseMessage ?
                            <>
                              {
                                formSuccess ?
                                    !notice ?
                                        <Alert severity="success" sx={{mb: "20px"}}>{responseMessage}</Alert>
                                        :
                                        <Alert severity="warning" sx={{mb: "20px"}}>{responseMessage}</Alert>
                                    :
                                    formSuccess != null ?
                                        <Alert severity="error" sx={{mb: "20px"}}>{responseMessage}</Alert>
                                        :
                                        <></>
                              }
                            </>
                            :
                            <>
                            </>
                      }
                      <form onSubmit={handleSubmit}>
                        <div className={(isDarkMode(darkMode) ? "b-border-dark" : "") + " row"}>
                          {
                            newsletterAskFirstname ?
                                <>
                                  <div className={calculateColumns() + " p-l-5 p-r-5"}>
                                    <div className="input-class form-group form-group-default bg-white b-transparent-master required" style={{backgroundColor: "transparent !important"}}>
                                      <label htmlFor="first_name" className={textColor(darkMode) + " bold text-uppercase letter-sp-normal fs-12"}>First
                                        name</label>
                                      <input id="first_name" name="first_name" type="text" className="form-control" required style={{backgroundColor: "transparent !important"}} />
                                    </div>
                                  </div>
                                </>
                                :
                                <></>
                          }
                          {
                            newsletterAskLastname ?
                                <>
                                  <div className={calculateColumns() + " p-l-5 p-r-5"}>
                                    <div className="input-class form-group form-group-default bg-white b-transparent-master required" style={{backgroundColor: "transparent !important"}}>
                                      <label htmlFor="last_name" className={textColor(darkMode) + " bold text-uppercase letter-sp-normal fs-12"}>Last
                                        name</label>
                                      <input id="last_name" name="last_name" type="text" className="form-control" style={{backgroundColor: "transparent !important"}} />
                                    </div>
                                  </div>
                                </>
                                :
                                <></>
                          }

                          {
                            newsletterAskPhone ?
                                <div className={calculateColumns() + " p-l-5 p-r-5"}>
                                  <div className="input-class form-group form-group-default bg-white b-transparent-master required" style={{backgroundColor: "transparent !important"}}>
                                    <label htmlFor="phone" className={textColor(darkMode) + " bold text-uppercase letter-sp-normal fs-12"}>Phone</label>
                                    <input id="phone" name="phone" type="tel" className="form-control"
                                           style={{backgroundColor: "transparent !important"}}
                                        // pattern="[0-12]{3}"
                                           placeholder="" required/>
                                  </div>
                                </div>
                                : <></>
                          }
                          {
                            <>
                              <div className={"col-12 p-l-5 p-r-5"}>
                              <div className="form-group form-group-default required input-group bg-transparent b-a b-transparent-master row d-flex align-items-center m-auto">
                                <div className={'col required'}>
                                  <label htmlFor="frm_newsletter_email" className={textColor(darkMode) + " text-master p-l-0"}>Email Address</label>
                                  <input type="email" name="frm_newsletter_email" id="frm_newsletter_email"
                                         data-ng-model="newsletter_email"
                                         className={textColor(darkMode) + " form-control text-master required pull-left required p-l-0"}
                                         placeholder="Example@mailserver.com" aria-required="true"
                                         aria-invalid="true" required />
                                </div>
                                <div className={'col-auto'}>
                                  <span className="input-group-btn pull-right">
                                    <button className="btn btn-master m-r-10 btn-cons b_r_3" type="submit">
                                      {
                                       formSubmitting && <FontAwesomeIcon icon={faSpinner} spin />
                                      }
                                      <span>
                                        Subscribe!
                                      </span>
                                    </button>
                                  </span>
                                </div>
                              </div>
                              </div>
                            </>
                          }
                        </div>
                      </form>
                  </div>
                </div>
              </div>
              {/*<div className="bg-overlay"*/}
              {/*     style={{*/}
              {/*       backgroundColor: 'white',*/}
              {/*       opacity: 0.8,*/}
              {/*       position: 'absolute',*/}
              {/*       left: 0,*/}
              {/*       top: 0,*/}
              {/*       width: "100%",*/}
              {/*       height: '100%',*/}
              {/*       zIndex: 0*/}
              {/*}} />*/}
            </section>
          </>
        }
      </>
  )
}