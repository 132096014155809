import Image from 'next/image';

import Checkers from '../../../helpers/class/Checkers';
import {useSelector} from "react-redux";
import {useEffect} from "react";

export default function HeaderLogoAltMode ( props ) {
  let logo_height = 30;
  let logo_alt_height = 30;
  let logo_factor = 1;
  let logo_alt_factor = 1;

  const logoProps = props.logoProps;

  if ( props.navbarModeClass.match( 'Header-Dark-Transparent' ) ) {
    logo_factor = logoProps.logoDarkOriginWidth / logoProps.logoDarkOriginHeight;
    if ( logoProps.logoDarkHeigth != null && logoProps.logoDarkHeigth > 0 ) {
      logo_height = logoProps.logoDarkHeigth;
    }
  } else {
    logo_factor = logoProps.logoLightOriginWidth / logoProps.logoLightOriginHeight;
    if ( logoProps.logoLightHeigth != null && logoProps.logoLightHeigth > 0 ) {
      logo_height = logoProps.logoLightHeigth;
    }
  }

  if ( logoProps.logoMode ) {
    logo_alt_factor = logoProps.logoDarkOriginWidth / logoProps.logoDarkOriginHeight;
    if ( logoProps.logoDarkHeigthAlt != null && logoProps.logoDarkHeigthAlt > 0 )
      logo_alt_height = logoProps.logoDarkHeigthAlt;
  } else {
    logo_alt_factor = logoProps.logoLightOriginWidth / logoProps.logoLightOriginHeight;
    if ( logoProps.logoLightHeigthAlt != null && logoProps.logoLightHeigthAlt > 0 )
      logo_alt_height = logoProps.logoLightHeigthAlt;
  }

  const logo_widthInit = logo_height * logo_factor;
  const logo_alt_widthInit = logo_alt_height * logo_alt_factor;

  let logoUrl = () => {
    if (props.app.builder.builder_header_logo_mode_sticky=='0') {
      return logoProps.logoLight
    }
    if (props.app.builder.builder_header_logo_mode_sticky=='1') {
      return logoProps.logoDark
    }

    return props.navbarModeClass.match('Header-Dark-Transparent') ? logoProps.logoDark : logoProps.logoLight;
  }

  const logoAltUrl = logoProps.logoMode ? logoProps.logoDark : logoProps.logoLight;

  const isHomePage = useSelector(state => state['appSettings']['isHomepage'])

  return (
    <>
      {
        isHomePage == 1 ?
            <>
                {Checkers.isValidString( logoUrl() ) ?
                    <>
                      <img width={logo_widthInit} height={logo_height} src={logoUrl()} alt="logo_alt_header" className={"logo_header logo"} loading="lazy" />
                    </>
                    :
                    <></>
                }
              </>
          :
            <>
            </>
      }
      {
        Checkers.isValidString( logoAltUrl ) ?
            <img width={logo_alt_widthInit} height={logo_alt_height} src={logoAltUrl} alt="logo_alt_header" className={"logo_header  " + (isHomePage ? "alt logo" : "logo")} loading="lazy" />
            :
            <></>
      }
      {
        !Checkers.isValidString( logoAltUrl) && !Checkers.isValidString( logoUrl()) ?
            <>
              <h2 className={logoProps.logoMode ? 'text-white' : 'text-black'}>{props.pageTitle}</h2>
            </>
            : <></>
      }
    </>
  );
}
