import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FooterSkeleton from './Skeletons/FooterSkeleton';
import * as appContext from './AppContext';
import Contact from './Contact';
import Footer from './Footer';
import ViewPort from './ViewPort';
import GoogleMapSection from "./GoogleMapSection";
import Checkers from "../helpers/class/Checkers";
import {fetchBuilderSettingByKey} from "../helpers/MethodHelper";
import NewsletterSection from "./templates/index/sections/NewsletterSection";
import {ElfsightWidget} from "react-elfsight-widget";
import {faViber, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowUp as falArrowUp } from '@fortawesome/pro-solid-svg-icons';

interface BottomSectionsProps {
  contactSection: any
  footerSection: any
  app_data: any
  head: any
  app: any
  locations: any
  footer: any
  isDesktop?: any
  captchaSiteKey?: any
  footerRef?: any
  footerPaddingClass?: string
  htmlSections?: any
}

export default function BottomSections ( props : BottomSectionsProps ) {

  const contact_section = props.contactSection;
  const footer_section = props.footerSection;


  const isMobile = useSelector(state => state['appSettings']['isMobile'])
  const isTablet = useSelector(state => state['appSettings']['isTablet'])

  const app_data = props.app_data;
  const head = props.head;
  const app = props.app;
  const locations = props.locations;
  const footer = props.footer;
  const isDesktop = props.isDesktop;

  const captchaSiteKey = props.captchaSiteKey;

  const contactFormInFooter = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_contact_in_different_page') == 1 ? false : true
  );

  const showChatIconsMobile = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_chat_icons_side_show_mobile') == 1 ? true : false
  );

  const showChatIconsDesktop = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_chat_icons_side_show_desktop') == 1 ? true : false
  );

  const showChatIconWhatapp = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_chat_icon_whatsapp')
  );

  const showChatIconViber = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_chat_icon_viber')
  );

  const enableNewsletter = app.enableNewsletter

  const newsletterAskFirstname = app.newsletterAskFirstname

  const newsletterAskLastname = app.newsletterAskLastname

  const newsletterAskPhone = app.newsletterAskPhone

  const newsletterTitle = app.newsletterTitle

  const newsletterSubtitle = app.newsletterSubtitle

  const newsletterDesc = app.newsletterDesc

  const newsletterBackground = app.newsletterBgColor

  const newsletterDarkMode = app.builder.builder_newsletter_dark_mode

  const htmlSections = props.htmlSections !=null && props.htmlSections.hasOwnProperty('app_sections') ? props.htmlSections.app_sections : null

  const newsletterBackgroundHandler = () => {
    if (Checkers.isValidString(props.app.newsletterImage)) {
      return props.app.newsletterImage;
    }

    if (props.app.pageMedia != null && props.app.pageMedia.length>=2) {
      return props.app.pageMedia[1].image
    }

    return Checkers.isValidString(props.app.newsletterImage)?props.app.newsletterImage:null
  }

  return (
      <>

        {
          ( (Checkers.valueToBoolean(isMobile) && showChatIconsMobile) || (Checkers.valueToBoolean(isDesktop) && showChatIconsDesktop) ) ?
              <>
                {
                  (Checkers.isValidString(showChatIconViber) || Checkers.isValidString(showChatIconWhatapp)) ?
                      <>
                        <div className={'chat-icons'}>
                          {
                            Checkers.isValidString(showChatIconViber) ?
                                <>
                                  <a className={'icon viber'} href={showChatIconViber} target={'_blank'} rel="noreferrer">
                                    <FontAwesomeIcon icon={faViber} className="" size="xl"/>
                                    {/*<span>*/}
                                    {/*  Viber*/}
                                    {/*</span>*/}
                                  </a>
                                </>
                                :
                                <>
                                </>
                          }
                          {
                            Checkers.isValidString(showChatIconWhatapp) ?
                                <>
                                  <a className={'icon whatsapp'} href={showChatIconWhatapp} target={'_blank'} rel="noreferrer">
                                    <FontAwesomeIcon icon={faWhatsapp} className="" size="xl"/>
                                    {/*<span>Whatsapp</span>*/}
                                  </a>
                                </>
                                :
                                <>
                                </>
                          }
                        </div>
                      </>
                      :
                      <>
                      </>
                }
              </>
              :
              <>
              </>
        }

        {
          htmlSections != null && Checkers.isValidArray(htmlSections) ?
              <>
              <div className={'container-fluid overflow-hidden'}>
                {
                  htmlSections.map((section, key) => (
                      section.app_section_content != null ?
                          section.app_section_content.includes('elfsight-app') ?
                              section.app_section_content.split('elfsight-app-')[1] != null ?
                                  <ElfsightWidget key={key}
                                                  widgetID={section.app_section_content.split('elfsight-app-')[1].split('"></div>')[0]}/>
                                  :
                                  <></>
                              :
                              <></>
                          :
                          <></>
                  ))
                }
              </div>
              </>
              :
              <></>
        }
        {
            enableNewsletter ?
                <NewsletterSection
                    newsletterAskFirstname={newsletterAskFirstname}
                    newsletterAskLastname={newsletterAskLastname}
                    newsletterAskPhone={newsletterAskPhone}
                    title={newsletterTitle}
                    subtitle={newsletterSubtitle}
                    description={newsletterDesc}
                    newsletterBgImage={newsletterBackgroundHandler()}
                    newsletterBackground={newsletterBackground}
                    darkMode={newsletterDarkMode}
                />
                :
                <></>
        }
        {
          contactFormInFooter ?
              <>
                {
                    contact_section &&
                    <Contact
                        isDesktop={isDesktop}
                        contactFormTitle={app.contactFormTitle}
                        locations={locations != null ? locations.hasOwnProperty('page_locations') ? locations.page_locations : null : null}
                        captchaSiteKey={captchaSiteKey}
                        showContactSection={app.showContactSection}
                        showContactForm={app.showContactForm}
                        showName={app.contactAskName}
                        showEmail={app.contactAskEmail}
                        showPhone={app.contactAskPhone}
                        showDates={app.contactAskDates}
                        showDestination={app.contactAskDestination}
                        searchbarRadius={app.searchbarRadius != null ? app.searchbarRadius.length > 0 ? app.searchbarRadius.length : null : null}
                        contactPhoneOverride={app.builder.builder_contact_page_phone}
                        contactEmailOverride={app.builder.builder_contact_page_email}
                        contactTitleOverride={app.builder.builder_contact_page_title}
                    />
                }
              </>
              :
              <>
              </>
        }
        {
          footer_section ?
              <ViewPort
                  ref={props.footerRef}
                  parentClass="row w-100 m-0"
                  skeletonItem={<FooterSkeleton height={app_data.has_android_app || app_data.has_ios_app ? 250 : 172}/>}
              >
                <Footer
                    footerPaddingClass={props.footerPaddingClass}
                    isDesktop={isDesktop}
                    designMode={app.designMode}
                    menu={footer.menu}
                    footerContent={footer}
                    title={head.data.hasOwnProperty('titleOfficial') && head.data.titleOfficial != null ? head.data.titleOfficial : head.data.hasOwnProperty('title') ? head.data.title : null}
                    fixed={(app.enableBooking && app.bookingSearchbarWidth && !(app.bookingSearchbarPosition))}
                    iosApp={app_data.has_ios_app ? app_data.ios_app_link : null}
                    androidApp={app_data.has_android_app ? app_data.android_app_link : null}
                    iosAppText={app_data.has_ios_app ? app_data.ios_app_text : null}
                    androidAppText={app_data.has_android_app ? app_data.android_app_text : null}
                    hasLoggiaBadge={app_data.has_loggia_badge}
                    loggiaBadgeText={app_data.has_loggia_badge ? app_data.loggia_badge_text : null}
                    registrationNumber={app_data.registration_number}
                    registrationNumberText={app_data.registration_number_text}
                />
              </ViewPort>
              :
              ''
        }

        <button
            type="button"
            className="btn btn-lg btn-primary btn-design-color rounded-circle shadow-5 z-index-5"
            id="btn-back-to-top"
            aria-label="Back to top"
        >
          <FontAwesomeIcon className="fs-16" icon={falArrowUp}/>
        </button>
      </>
  )
}
