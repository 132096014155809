import {useRouter} from "next/router";
import {useEffect, useState} from "react";
import {LinearProgress} from "@mui/material";
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../helpers/MethodHelper";

export const HeaderPacer = () => {

    const mainPacer = useSelector(state => state['uiData']['mainPacer'])

    const rgbDesignColor = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'designColor')
    );

    const scrollToTop = () => {
        if (window!=undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.scrollTo({ top:0, left:0, behavior: "instant"})
        }
    }

    useEffect( () => {
        if (mainPacer) {
            scrollToTop()
        }
    }, [scrollToTop, mainPacer])

    return (
        mainPacer ?
            <div className={'pacer'} style={{color: rgbDesignColor}}>
                <LinearProgress sx={{
                    backgroundColor: 'white'
                }} color={'inherit'} />
            </div>
            :
            <></>
    )
}