import Skeleton from "react-loading-skeleton";
import React from "react";

export const MainLayoutSkeleton = () => {

    return (
        <>

            <section>
                <div className={" no-padding no-overflow h-100 p-b-40"}>
                    <Skeleton height={400} width={'100%'}/>
                </div>
                <div className={"container"}>
                    <div className={'row'}>
                        <div className={"col-md-8"}>
                            <Skeleton height={60} width={'100%'} className={'m-b-20'}/>
                            <Skeleton height={27} width={'100%'} className={'m-b-20'}/>
                            <Skeleton height={27} width={'100%'} className={'m-b-20'}/>
                            <Skeleton height={27} width={'100%'} className={'m-b-20'}/>
                            <Skeleton height={150} width={'100%'} className={'m-b-20'}/>
                            <Skeleton height={27} width={'100%'} className={'m-b-20'}/>
                            <Skeleton height={27} width={'100%'} className={'m-b-20'}/>
                        </div>
                        <div className={"col-md-4"}>
                            <Skeleton height={150} width={'100%'} className={'m-b-20'}/>
                            <Skeleton height={250} width={'100%'} className={'m-b-20'}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}