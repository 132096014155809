import React, {useEffect} from 'react';

import Checkers from '../../helpers/class/Checkers';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../helpers/MethodHelper";

export default function ContactLocations ( props ) {
  let url = null;
  if (props.latitude != null && props.longitude != null) {
    url = 'https://www.google.com/maps/search/?api=1&amp;query=' + props.latitude + ',' + props.longitude;
  } else {
    url = null;
  }
  let locations_class = 'col-12 col-sm-12 col-md-6 pe-md-5';
  if (!props.showContactForm) {
    locations_class = 'col-md-4 offset-md-4 col-sm-12 offset-sm-0';
  }

  const showBranchesLocations = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_contact_show_branches') == '1' ? true : false
  );

  const contactEmail = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_contact_page_email')
  );

  const contactEmailOverride = Checkers.isValidString(props.contactEmailOverride) ? props.contactEmailOverride : contactEmail

  // useEffect( () => {
  //   console.log("forDebug", "contactEmailOverride", contactEmailOverride)
  //   console.log("forDebug", "showBranchesLocations", showBranchesLocations)
  //   console.log("forDebug", "props.locations", props.locations)
  // })

  return (
      <div className={locations_class}>
        <h2 className="m-b-20 hidden-xs">{props.contactFormTitle != null ? props.contactFormTitle : 'How can we assist you?'}</h2>
        {
          props.locations != null ?
              <div className="d-flex flex-row flex-wrap w-100 m-0">
                {
                  props.locations.map((location, key) => {
                    if (key>0 && !showBranchesLocations) {
                      return
                    }

                    return (
                        <>
                          <div key={key} className="pe-3">
                            {location.location_title != null &&
                                <p className="text-uppercase letter-sp-normal fw-normal hint-text m-b-0">
                                  {
                                    Checkers.isValidString(props.contactTitleOverride) ?
                                        props.contactTitleOverride
                                        :
                                        location.location_title
                                  }
                                </p>
                            }

                            <address className="my-2 mb-3">
                              {Checkers.isValidString(location.address) ? location.address : ''}
                              <br/>
                              {Checkers.isValidString(location.city) ? location.city : ''}
                              {Checkers.isValidString(location.zip) ? ', ' + location.zip : ''}
                              <br/>
                              {Checkers.isValidString(location.country) ? location.country : ''}
                            </address>
                            {
                              url != null ?
                                  <p className="text-uppercase letter-sp-normal fw-bold small-text hint-text no-margin p-r-10">
                                    <a target="_blank" className="view-map" href={url} rel="noreferrer">
                                      View on map
                                    </a>
                                  </p>
                                  : ''
                            }
                            {
                              location.hasOwnProperty('items') ?
                                  location.items.length > 0 ?
                                      location.items.map((item, key) => (
                                          item.slug.includes('phone') ?
                                              item.value != null ?
                                                  <React.Fragment key={'location-items-phone-' + key} >
                                                    {
                                                      Checkers.isValidString(props.contactPhoneOverride) ?
                                                          <React.Fragment key={'location-items-' + key} />
                                                          :
                                                          <>
                                                            <p key={key} className="letter-sp-normal fw-bold no-margin p-r-10">
                                                              <span className="text-uppercase letter-sp-normal fw-bold text-master no-margin fs-12 p-r-10">Tel</span>
                                                              {
                                                                item.phone_prefix != null ?
                                                                    <a href={'tel:+' + item.phone_prefix + item.value}
                                                                       className="fs-14 fw-bold hint-text text-master text-design-color">(+{item.phone_prefix}) {item.value}</a>
                                                                    :
                                                                    <a href={'tel:' + item.value}
                                                                       className="fs-14 fw-bold hint-text text-master text-design-color">{item.value}</a>
                                                              }
                                                            </p>
                                                          </>
                                                    }
                                                  </React.Fragment>
                                                  : <React.Fragment key={'location-items-' + key} />
                                              :
                                              <React.Fragment key={'location-items-email-' + key} >
                                                {
                                                  Checkers.isValidString(contactEmailOverride) ?
                                                      <React.Fragment key={'location-items-email-' + key} />
                                                      :
                                                      <>
                                                        {
                                                          item.slug.includes('email') ?
                                                              item.value != null ?
                                                                  <p key={key} className="letter-sp-normal fw-bold no-margin p-r-10">
                                                                    <span className="text-uppercase letter-sp-normal fw-bold text-master no-margin fs-12 p-r-10">Email</span>
                                                                    <a href={'mailto:' + item.value} className="fs-14 fw-bold hint-text text-master text-design-color">{item.value}</a>
                                                                  </p>
                                                                  :
                                                                  <></>
                                                              :
                                                              <></>
                                                        }
                                                      </>
                                                }
                                              </React.Fragment>
                                      ))
                                      : ''
                                  : ''
                            }
                            {
                                Checkers.isValidString(props.contactPhoneOverride) ?
                                  <>
                                    <p key={key} className="contactPhoneOverride letter-sp-normal fw-bold no-margin p-r-10">
                                      <span className="text-uppercase letter-sp-normal fw-bold text-master no-margin fs-12 p-r-10">Tel</span>
                                      <a href={'tel:' + props.contactPhoneOverride}
                                         className="fs-14 fw-bold hint-text text-master text-design-color">{props.contactPhoneOverride}</a>
                                    </p>
                                  </>
                                  : <></>
                            }

                            {
                                Checkers.isValidString(contactEmailOverride) ?
                                  <>
                                    <p key={key} className="letter-sp-normal fw-bold no-margin p-r-10">
                                  <span
                                      className="text-uppercase letter-sp-normal fw-bold text-master no-margin fs-12 p-r-10">Email</span>
                                      <a href={'mailto:' + contactEmailOverride}
                                         className="fs-14 fw-bold hint-text text-master text-design-color">{contactEmailOverride}</a>
                                    </p>
                                  </>
                                    :
                                    <></>
                            }
                          </div>
                        </>
                    )
                  })
                }
              </div>
              : ''
        }
      </div>
  )
}

