import {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoSsr from '@mui/material/NoSsr';
import dynamic from 'next/dynamic';

import Checkers from '../../../helpers/class/Checkers';
import HeaderCustomModal from '../partials/HeaderCustomModal';
import {fetchBuilderSettingByKey, getHomepageUrl} from "../../../helpers/MethodHelper";
import {useSelector} from "react-redux";
import Link from "next/link";

const HeaderLogo = dynamic( () => import( '../logo/HeaderLogo' ), { ssr: true });
const HeaderLogoAltMode = dynamic( () => import( '../logo/HeaderLogoAltMode' ), { ssr: true });
const HeaderNavigation = dynamic( () => import( '../menu/HeaderNavigation' ), { ssr: true });
const ContactIcons = dynamic( () => import( '../partials/ContactIcons' ), { ssr: true });

export default function HeaderTypeThree ( props ) {

  // useEffect(() =>{
  //   console.log("props.apps", props.apps)
  // })

  const [ show, setShow ] = useState( false );
  const handleShow = () => {
    setShow( true );
  }
  const handleHide = () => {
    setShow( false );
  }

  const useRedirectUrl = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'useRedirectUrl')
  );

  const homePageUrl = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'logoUrl')
  );

  const homepageUrl = Checkers.isValidString(getHomepageUrl(props.referer, useRedirectUrl, homePageUrl)) ? getHomepageUrl(props.referer, useRedirectUrl, homePageUrl) : "#"

  return (
    <>
      <header ref={props.setHeaderDiv} className={`Header ${props.navbarModeClass} d-flex flex-row align-items-center`}
        data-pages={props.resizeClass != '' ? 'header' : ''}
        data-builder-header="minimized"
        data-pages-header={props.resizeClass != '' ? 'autoresize' : ''}
        data-pages-resize-class={props.resizeClass}
        style={{ height: 'auto', zIndex: props.zIndex > 0 ? props.zIndex : 10 }}
      >
        <div className={Checkers.isValidString( props.containerClass ) ? props.containerClass : 'container'}>
          <div className="d-flex flex-row-reverse flex-md-row justify-content-between nav w-100">
            <button type="button" className="navbar-toggle collapsed border-0 me-md-auto visible" aria-expanded="false" onClick={handleShow}>
              <span className="sr-only">Toggle navigation</span>
              <FontAwesomeIcon icon={props.faGripLines} size="2x"/>
            </button>
            <Link  href={homepageUrl}>
              <a className="navbar-brand me-md-auto py-1 px-0" title={'logo'}>
                {props.bgTransparent == true ?
                    <HeaderLogoAltMode
                        app={props.app}
                        navbarModeClass={props.navbarModeClass}
                        resizeClass={props.resizeClass}
                        logoProps={props.logoProps}
                        pageTitle={props.pageTitle}
                    />
                    :
                    <HeaderLogo
                        navbarModeClass={props.navbarModeClass}
                        resizeClass={props.resizeClass}
                        logoProps={props.logoProps}
                        pageTitle={props.pageTitle}
                    />
                }
              </a>
            </Link>
          </div>
        </div>
      </header>
      <HeaderCustomModal
        modalName={'side_menu'}
        className="Modal-Header slide-left"
        modalSize="lg"
        closeModal={handleHide}
        showModal={show}
        setShowModal={setShow}
        modalBodyClass="Modal__body navbar-menu padding-0 d-flex flex-column justify-content-between"
        falTimes={props.falTimes}
      >
        <ul className="navbar-nav justify-content-start">
          <HeaderNavigation
            referer={props.referer}
            menu={props.menu}
            partials={props.partials}
            headerPartialBook={false}
            modalDropdownClass={'shadow-none position-relative mt-1 ps-2 pe-2 w-100'}
            contactIcons={null}
            menuItemDisableWrap={true}
            handleHide={handleHide}
          />
        </ul>
        <div className="navbar-nav justify-content-start mb-5">
          <NoSsr>
            <HeaderNavigation
              referer={props.referer}
              menu={props.menu}
              partials={props.partials}
              modalPartials={true}
              contactIcons={null}
              menuItemDisableWrap={true}
              handleHide={handleHide}
            />
            {
              props.hasOwnProperty( 'contactIcons' ) && props.contactIcons != null &&
                <ul className="Contact-Icons mt-1 mb-1 ms-0 d-xs-flex d-sm-flex flex-row" style={{ margin: '5px 25px' }}>
                  <ContactIcons contactIcons={props.contactIcons} modalDropdownClass={'shadow position-fixed mb-3 ps-2 pe-2 w-auto'} side="left"/>
                </ul>
            }
          </NoSsr>
        </div>
      </HeaderCustomModal>
    </>
  );
}
