
export default function LoggiaSVG () {
  return (
    <svg className="loggia_logo_cube" data-name="loggia_logo_cube"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 100" aria-hidden="true">
      <polygon className="loggia_logo_cube_right"
        points="49.2,45.1 91.7,22 96.6,70.9 49.2,100 "/>
      <polygon className="loggia_logo_cube_left"
        points="0,70.9 5,22 47.5,45.1 47.5,100 "/>
      <polygon className="loggia_logo_cube_top"
        points="6.1,20.6 48.3,0 90.5,20.6 48.3,43.6 "/>
    </svg>
  )
}
