import Image from 'next/image';

import Consts from '../../../helpers/class/Consts';
import FooterMenu from '../menu/FooterMenu';
import LoggiaSVG from '../partials/LoggiaSVG';

export default function FooterWithoutApps ( props ) {

  return (
    <footer className={'footer bg-design-color pt-5 xs-p-b-95 mt-auto ' + props.footerClass + " " + (props.footerPaddingClass!=null?props.footerPaddingClass:'') } style={ props.fixed ? { paddingBottom: '7.625rem' } : {}}>
      <div className="container">
        <div className={'row'}>
          <div className={'col-12 col-lg-6 justify-content-center justify-content-lg-start'}>
            <FooterMenu menu={props.menu}/>
          </div>
          <div id={'w3c_div'} className={'col-12 col-lg-6 justify-content-center justify-content-lg-end text-lg-end py-3 py-lg-0'} style={{ textAlign: 'center' }}>
            <p className={'fs-11'}>Copyright &copy; {props.title}. All rights reserved.</p>
            <a id={'w3c'} href={'https://validator.w3.org/nu/?doc='+window.location} aria-labelledby={'w3c_div'} target="_blank" rel="noreferrer" title={'w3c'}>
              <Image
                layout={'fixed'}
                width={56}
                height={20}
                src={Consts.W3cValidatorSvg}
                alt="w3c"
              />
            </a>
            <form id="ackecker_form" target="_blank" method="post"
              action="https://achecker.achecks.ca/checker/index.php" style={{ display: 'inline-block' }}
              className="ng-pristine ng-valid">
              <input type="hidden" name="uri" id="checkuri" value="https://example.com"/>
              <a id={'wcag_form_submit'} href={'https://achecks.org/checker/index.php'} aria-labelledby={'ackecker_form'} target="_blank" rel="noreferrer" title={'wcag_form_submit'}>
                <Image
                  layout={'fixed'}
                  width={56}
                  height={20}
                  src={Consts.WcagValidatorSvg}
                  alt="wcag"
                />
              </a>
            </form>
          </div>
        </div>
        <div className={'row justify-content-center text-center py-3'}>
          <a href="https://www.loggia.gr" target="_blank" aria-label="Made with Loggia's SimpleLodge"
            title="Made with Loggia's SimpleLodge" rel="noreferrer" className="w-auto">
            <LoggiaSVG/>
          </a>
        </div>
      </div>
    </footer>
  )
}
