import HeaderMenu from './HeaderMenu';
import HeaderPartials from './HeaderPartials';

export default function HeaderNavigation ( props ) {

  const menuItemDisableWrap = props.hasOwnProperty('menuItemDisableWrap') ? props.menuItemDisableWrap : false

  const handleHide = props.hasOwnProperty('handleHide') ? props.handleHide : undefined

  return (
    <>
      {
        props.modalPartials == true || props.headerPartials == true ?
          <HeaderPartials
            partials={props.partials}
          />
          :
          <HeaderMenu
            referer={props.referer}
            menu={props.menu}
            partials={props.partials}
            headerPartialBook={props.headerPartialBook}
            modalDropdownClass={props.modalDropdownClass}
            contactIcons={props.hasOwnProperty( 'contactIcons' ) ? props.contactIcons : null}
            menuItemDisableWrap={menuItemDisableWrap}
            handleHide={handleHide}
          />
      }
    </>
  )
}
