
export default function ContactPhones ( props ) {
  return (
    props.phoneIconData.split( ',' ).map( ( phone, key ) => (
      // eslint-disable-next-line react/jsx-key
      <li className={key == 0 ? 'dropdown-ul-item mt-20px' : 'dropdown-ul-item mt-3'} key={key}>
        <a className="dropdown-item text-wrap"
          href={
            phone.split( ':' ).length > 1 ?
              'tel:' +  phone.split( ':' )[1].replace( /\s+/g, '' )
              :
              'tel:' +  phone.replace( /\s+/g, '' )
          }>
          {
            phone.split( ':' ).length > 1 ?
              phone.split( ':' )[0].replace( /\s+/g, '' )
              :
              phone.replace( /\s+/g, '' )
          }
        </a>
      </li>
    ) )
  )
}
