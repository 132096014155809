import {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoSsr from '@mui/material/NoSsr';

import Checkers from '../../../helpers/class/Checkers';
import HeaderLogo from '../logo/HeaderLogo';
import HeaderLogoAltMode from '../logo/HeaderLogoAltMode';
import HeaderNavigation from '../menu/HeaderNavigation';
import ContactIcons from '../partials/ContactIcons';
import HeaderCustomModal from '../partials/HeaderCustomModal';
import SearchIcon from '../partials/SearchIcon';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey, getHomepageUrl} from "../../../helpers/MethodHelper";
import Link from "next/link";

export default function HeaderTypeOne ( props ) {

  const showSearch = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_show_menu_search_icon') == 1
  );

  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  }
  const handleHide = () => {
    setShow(false);
  }

  const showMenuItemsInBurger = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_menu_items_in_burger')
  );

  const useRedirectUrl = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'useRedirectUrl')
  );

  const homePageUrl = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'logoUrl')
  );

  const homepageUrl = Checkers.isValidString(getHomepageUrl(props.referer, useRedirectUrl, homePageUrl)) ? getHomepageUrl(props.referer, useRedirectUrl, homePageUrl) : "#"

  return (
      <>
        <header ref={props.setHeaderDiv}
                className={'test Header navbar\ ' + props.navbarModeClass + props.textColor + '\ flex-nowrap border-0 px-0 mb-0'}
                data-pages={props.resizeClass != '' ? 'header' : ''}
                data-builder-header="different"
                data-pages-header={props.resizeClass != '' ? 'autoresize' : ''}
                data-pages-resize-class={props.resizeClass}
                style={{height: 'auto', zIndex: props.zIndex > 0 ? props.zIndex : 1023}}
        >
          <div id={'container'}
               className={Checkers.isValidString(props.containerClass) ? props.containerClass : 'container'}>
            <Link href={homepageUrl}>
              <a className="navbar-brand d-flex flex-row justify-content-start py-0 px-0" title={'logo'}>
                {
                  props.headerProps.builder_header_mode == 'transparent' ?
                      <>
                        <HeaderLogoAltMode
                            app={props.app}
                            navbarModeClass={props.navbarModeClass}
                            resizeClass={props.resizeClass}
                            logoProps={props.logoProps}
                            pageTitle={props.pageTitle}
                        />
                      </>
                      :
                      <HeaderLogo
                          navbarModeClass={props.navbarModeClass}
                          resizeClass={props.resizeClass}
                          logoProps={props.logoProps}
                          pageTitle={props.pageTitle}
                      />
                }
              </a>
            </Link>
            {
              props.hasOwnProperty('contactIcons') ?
                  props.contactIcons != null ?
                      <ul className="minimized-partials navbar-nav d-flex flex-row justify-content-end ms-auto mb-2 mb-md-0 hidden-xs hidden-sm hidden">
                        <ContactIcons contactIcons={props.contactIcons}/>
                        {/*{*/}
                        {/*  <SearchIcon/>*/}
                        {/*}*/}
                      </ul>
                      : ''
                  : ''
            }

            {/*<ul className="minimized-partials navbar-nav d-flex flex-row justify-content-end ms-auto mb-2 mb-md-0 visible-xs visible-sm hidden">*/}
            {/*  {*/}
            {/*    <SearchIcon/>*/}
            {/*  }*/}
            {/*</ul>*/}


            <div className={(showMenuItemsInBurger == '1' ? "d-flex  navbar-toggle-collapse" : "d-none") + " w-100 "}>
              <ul className="navbar-nav flex-row justify-content-end align-items-center ms-auto mb-2 mb-md-0">
                <ContactIcons contactIcons={props.contactIcons}/>
              </ul>
            </div>

            <div className={(showMenuItemsInBurger == '1' ? "d-none" : " navbar-toggle-collapse") + " "}>
              <ul className="navbar-nav flex-row justify-content-end align-items-center ms-auto mb-2 mb-md-0">
                <HeaderNavigation
                    referer={props.referer}
                    menu={props.menu}
                    partials={props.partials}
                    headerPartialBook={true}
                    modalDropdownClass={null}
                    contactIcons={props.hasOwnProperty('contactIcons') ? props.contactIcons : null}
                    handleHide={handleHide}
                />
              </ul>
            </div>

            {
              showSearch ?
                  <>
                  <div className={'d-flex'}>
                    <ul className="navbar-nav flex-row justify-content-end align-items-center ms-auto mb-2 mb-md-0">
                      <SearchIcon/>
                    </ul>
                  </div>
                  </>
                  : <></>
            }

            <button type="button"
                    className={"τεστ-2 navbar-toggle collapsed border-0 " + (showMenuItemsInBurger == '1' ? "d-inline-block" : "visible-xs visible-sm")}
                    aria-expanded="false" onClick={handleShow}>
              <span className="sr-only">Toggle navigation</span>
              <FontAwesomeIcon icon={props.faGripLines} size="2x"/>
            </button>

          </div>
        </header>
        <HeaderCustomModal
            modalName={'side_menu'}
            className="Modal-Header slide-right"
            modalSize="lg"
            closeModal={handleHide}
            showModal={show}
            setShowModal={setShow}
            modalBodyClass="Modal__body navbar-menu d-flex flex-column justify-content-between padding-0"
            falTimes={props.falTimes}
        >
          <ul className="navbar-nav justify-content-start">
            <HeaderNavigation
                referer={props.referer}
                menu={props.menu}
                partials={props.partials}
                headerPartialBook={false}
                modalDropdownClass={'shadow-none position-relative mt-1 ps-2 pe-2 w-100'}
                contactIcons={null}
                menuItemDisableWrap={true}
                handleHide={handleHide}
            />
          </ul>
          <ul className="minimized-partials navbar-nav justify-content-start d-flex flex-column mb-5">
            <NoSsr>
              <HeaderNavigation
                  referer={props.referer}
                  menu={props.menu}
                  partials={props.partials}
                  modalPartials={true}
                  contactIcons={null}
                  menuItemDisableWrap={true}
                  handleHide={handleHide}
              />
              {
                  props.hasOwnProperty('contactIcons') && props.contactIcons != null &&
                  <ul className="Contact-Icons mt-1 mb-1 me-0 d-xs-flex d-sm-flex flex-row"
                      style={{margin: '5px 25px'}}>
                    <ContactIcons contactIcons={props.contactIcons}
                                  modalDropdownClass={'shadow position-fixed mb-3 ps-2 pe-2 w-auto'} side="right"/>
                  </ul>
              }
            </NoSsr>
          </ul>
        </HeaderCustomModal>
      </>
  )

}
