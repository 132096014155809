
export default function DropdownArrow () {

  return (
    <li className="arrow">
      <svg className="position-absolute" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="14px" height="7px" viewBox="0 0 14 7" enableBackground="new 0 0 14 7">
        <polygon fill="#FFFFFF" points="0,0 7,7 14,0 14,7 0,7 "/>
        <polyline opacity="0.1" fill="none" stroke="#231F20" strokeMiterlimit="10" points="0,0 7,7 14,0 "/>
      </svg>
    </li>
  )
}
