import React, {Suspense} from "react";
import BottomSections from "./BottomSections";
import dynamic from "next/dynamic";
const Scripts = dynamic( () => import( './Scripts' ), { ssr: false });

export const FooterWrapper = (props) => {
    return (
        <>
            {/*<Suspense>*/}
                <BottomSections
                    footerPaddingClass={!props.isDesktop ? 'search-xs-p-b-70' : ''}
                    key={'bottom_section'}
                    isDesktop={props.isDesktop}
                    contactSection={props.app.showContactSection}
                    footerSection={true}
                    app_data={props.app_data}
                    app={props.app}
                    head={props.head}
                    locations={props.locations}
                    footer={props.footer}
                    htmlSections={props.hasOwnProperty('html_sections') ? props.html_sections : null}
                />
            {/*</Suspense>*/}
            <Scripts/>
        </>
    )
}