import Header from "../components/Header";
import React, {Suspense, useState} from "react";
import {Fancybox} from "@fancyapps/ui/dist/fancybox.esm";
import {HeaderPacer} from "../components/header/types/HeaderPacer";
import {FooterWrapper} from "../components/FooterWrapper";
import {LoaderComponent} from "../components/header/types/LoaderComponent";
import {useSelector} from "react-redux";
import {MainLayoutSkeleton} from "../components/header/types/MainLayoutSkeleton";


export default function MainLayout(props) {

    const [urlHref, setUrlHref] = useState(null);

    React.useEffect(() => {
        Fancybox.bind("[data-fancybox]", {
            Image: {
                zoom: false,
            },
        });
        if (window !== undefined) {
            setUrlHref(window.location.href + '/');
        }
    }, [urlHref]);

    const mainPacer = useSelector(state => state['uiData']['mainPacer'])

    const disablePacer = false

    return (
        <>
            <Header referer={urlHref} {...props} />
            <HeaderPacer />
            <LoaderComponent />

            {
                (!mainPacer || disablePacer) ?
                    props.children
                    :
                    <MainLayoutSkeleton />
            }
            <FooterWrapper {...props} />
        </>
    )
}