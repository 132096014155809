import React, { useEffect, useState } from 'react';
import { faGripLines as falGripLines } from '@fortawesome/pro-light-svg-icons';
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons';
import { faGripLines as fasGripLines } from '@fortawesome/pro-solid-svg-icons';

import Checkers from '../helpers/class/Checkers';

import HeaderDefault from './header/types/HeaderDefault';
import HeaderTypeFour from './header/types/HeaderTypeFour';
import HeaderTypeOne from './header/types/HeaderTypeOne';
import HeaderTypeThree from './header/types/HeaderTypeThree';
import HeaderTypeTwo from './header/types/HeaderTypeTwo';
import {HeaderPacer} from "./header/types/HeaderPacer";
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../helpers/MethodHelper";
import {faBars} from "@fortawesome/pro-regular-svg-icons/faBars";

export default function Header ( props ) {

  const menu = props.navigation!= undefined && props.navigation.hasOwnProperty('menu') ? props.navigation.menu : null

  const partials = props.navigation!= undefined && props.navigation.hasOwnProperty('partials') ? props.navigation.partials : null

  const phone = props.head.location[0].phone

  const containerClass = Checkers.objChildExists( props, 'containerClass' ) ? props.containerClass : null

  const pageTitle = props.head.data.title

  const contactIcons = () => {
    if (props.app.showContactIconsOnMenu) {
      return {
        phoneIcon: props.app.showPhoneIconOnMenu,
        chatIcon: props.app.showChatIconOnMenu,
        mailIcon: props.app.showMailIconOnMenu,
        phoneIconData: props.app.menuPhoneIconData,
        chatIconData: props.app.menuChatIconData,
        mailIconData: props.app.menuMailIconData
      }
    }

    return null
  }

  const app = props.app;

  const [ headerDiv, setHeaderDiv ] = useState( null );
  const [ referer, setReferer ] = useState( null );
  const property = props.hasOwnProperty( 'isHomepage' ) ? !props.isHomepage : true;

  useEffect( () => {

    if ( window !== undefined && document !== undefined ) {
      setReferer( window.location.origin + '/' );

      if ( !property && headerDiv && ( props.builder.builder_header_type != 'double' ) ) {
        const headerTop = headerDiv.getBoundingClientRect().top;
        window.addEventListener( 'scroll', function () {
          if ( this.scrollY > headerTop ) {
            headerDiv.classList.add('Header-Sticky-Top')
            headerDiv.classList.remove('Header-Sticky-Top-Not')
          } else {
            headerDiv.classList.remove('Header-Sticky-Top')
            headerDiv.classList.add('Header-Sticky-Top-Not')
          }
        });
        window.addEventListener( 'load', function () {
          if ( this.scrollY > headerTop ) {
            headerDiv.classList.add('Header-Sticky-Top')
            headerDiv.classList.remove('Header-Sticky-Top-Not')
          } else {
            headerDiv.classList.remove('Header-Sticky-Top')
            headerDiv.classList.add('Header-Sticky-Top-Not')
          }
        });
      }
    }

  }, [ headerDiv, property, props.builder.builder_header_type, referer ]);



  const menuIconAlt = useSelector(
      state => Checkers.valueToBoolean(fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_menu_alt_icon'))
  );

  let navbarModeClass = '';
  let navbarTwoModeClass = '';
  let resizeClass = '';
  let faGripLines = null;
  faGripLines = menuIconAlt ? faBars : fasGripLines;

  if ( app.designMode == true ) {
    navbarModeClass = 'Header-Dark';
  } else {
    navbarModeClass = 'Header-Light';
  }

  // console.log("props.builder.builder_header_type", props.builder.builder_header_type)

  if ( props.builder.builder_header_type != 'default' ) {

    if ( props.builder.builder_header_mode == 'transparent' ) {
      if ( Checkers.valueToBoolean( props.builder.builder_header_first_state_mode ) ) {
        navbarModeClass = 'Header-Dark';
      } else {
        navbarModeClass = 'Header-Light';
      }
      if ( Checkers.valueToBoolean( props.builder.builder_header_second_state_mode ) ) {
        resizeClass += 'Header-Dark border-bottom\ ';
      } else {
        resizeClass += 'Header-Light border-bottom\ ';
      }
      if ( Checkers.isValidString( props.builder.builder_header_color ) ) {
        resizeClass += ' Header-Custom-Color\ ';
      }
    } else {
      if ( Checkers.valueToBoolean( props.builder.builder_header_first_state_mode ) ) {
        navbarModeClass = 'Header-Dark';
      } else {
        navbarModeClass = 'Header-Light';
      }
      if ( Checkers.isValidString( props.builder.builder_header_color ) ) {
        navbarModeClass += ' Header-Custom-Color\ ';
      }
    }
    if ( props.builder.builder_header_type == 'double' ) {
      if ( Checkers.valueToBoolean( props.builder.builder_header_one_mode ) ) {
        navbarModeClass = 'Header-Dark';
      } else {
        navbarModeClass = 'Header-Light';
      }
      if ( Checkers.valueToBoolean( props.builder.builder_header_two_mode ) ) {
        navbarTwoModeClass = 'Header-Dark';
      } else {
        navbarTwoModeClass = 'Header-Light';
      }
      if ( Checkers.valueToBoolean( props.builder.builder_header_logo_mode ) ) {

      }
    }
    if ( !property && props.builder.builder_header_mode == 'transparent' ) {
      navbarModeClass += '-Transparent fixed-top\ ';
    } else {
      if ( Checkers.isValidString( resizeClass ) )
        navbarModeClass = resizeClass;
      resizeClass = '';
    }

    if ( property ) {
      navbarModeClass += "\ ";
    }
  }

  switch ( props.builder.builder_header_type ) {
  case 'different':
  case 'double':
  case 'minimized':
  case 'centered':
    if ( !Checkers.valueToBoolean( props.builder.builder_header_menu_bold ) ) {
      navbarModeClass += ' no-bold\ ';
      faGripLines = menuIconAlt ? faBars : fasGripLines;
    }
    break;
  }

  const headerProps = {
    builder_header_type: props.builder.builder_header_type,
    builder_header_mode: props.builder.builder_header_mode,
    builder_header_menu_bold: props.builder.builder_header_menu_bold,
    builder_header_one_mode: props.builder.builder_header_one_mode,
    builder_header_first_state_mode: props.builder.builder_header_first_state_mode,
    builder_header_second_state_mode: props.builder.builder_header_second_state_mode,
    builder_header_color: props.builder.builder_header_color,
    builder_header_logo_mode: props.builder.builder_header_logo_mode,
    builder_header_menu_color: props.builder.builder_header_menu_color,
    builder_header_two_mode: props.builder.builder_header_two_mode
  };
  const logoProps = {
    logoLightOriginHeight: app.logoLightOriginHeight,
    logoLightOriginWidth: app.logoLightOriginWidth,
    logoDarkOriginHeight: app.logoDarkOriginHeight,
    logoDarkOriginWidth: app.logoDarkOriginWidth,
    logoLightHeigth: app.logoLightHeigth,
    logoLightHeigthAlt: app.logoLightHeigthAlt,
    logoDarkHeigth: app.logoDarkHeigth,
    logoDarkHeigthAlt: app.logoDarkHeigthAlt,
    logoLight: app.logoLight,
    logoDark: app.logoDark,
    logoMode:
      Checkers.isValidString( props.builder.builder_header_type ) && props.builder.builder_header_type != 'default' ?
        Checkers.valueToBoolean( props.builder.builder_header_logo_mode )
        :
        navbarModeClass.match( 'Header-Dark' )
  }

  const stateForModal =
    props.builder.builder_header_type == 'default'?
      Checkers.valueToBoolean( app.designMode )
      : props.builder.builder_header_type == 'double'?
        Checkers.valueToBoolean( props.builder.builder_header_one_mode )
        : props.builder.builder_header_mode == 'transparent' ?
          Checkers.valueToBoolean( props.builder.builder_header_second_state_mode )
          : Checkers.valueToBoolean( props.builder.builder_header_first_state_mode )
  ;

  return (
    <>
      {Checkers.isValidString( props.builder.builder_header_color ) &&
        <style jsx global>{`
          .Header-Custom-Color{
            background-color: ${props.builder.builder_header_color};
          }
        `}</style>
      }
      {<style jsx global>{`
        .Header.Header-Dark svg,
        .Header.Header-Light svg,
        .Header.Header-Dark .navbar-nav .nav-link,
        .Header.Header-Light .navbar-nav .nav-link,
        .Header.minimized.Header-Dark .navbar-nav .nav-link,
        .Header.minimized.Header-Light .navbar-nav .nav-link,
        .Header.minimized.Header-Light .navbar-nav .nav-link svg {
          font-weight: ${ props.builder.builder_header_type == 'default' ? 600 :Checkers.valueToBoolean( props.builder.builder_header_menu_bold ) ? 600 : 400 }!important;
          ${Checkers.isValidString( props.builder.builder_header_menu_color ) ? 'color: ' + props.builder.builder_header_menu_color + '!important' : ''};
        }
        .Header .dropdown-menu > li > a{
          font-weight: ${Checkers.valueToBoolean( props.builder.builder_header_menu_bold ) ? 600 : 400}!important;
        }
        .Header.Header-Dark .btn, 
        .Header.Header-Light .btn, 
        .Header.Header-Dark .btn:hover:not(.active),
        .Header.Header-Light .btn:hover:not(.active),
        .Header.minimized.Header-Dark .btn, 
        .Header.minimized.Header-Light .btn, 
        .Header.minimized.Header-Dark .btn:hover:not(.active),
        .Header.minimized.Header-Light .btn:hover:not(.active){
          font-weight: ${ props.builder.builder_header_type == 'default' ? 600 :Checkers.valueToBoolean( props.builder.builder_header_menu_bold ) ? 600 : 400 }!important;
          ${Checkers.isValidString( props.builder.builder_header_menu_color ) ? 'background-color: transparent' : ''};
          ${Checkers.isValidString( props.builder.builder_header_menu_color ) ? 'color: ' + props.builder.builder_header_menu_color + '!important' : ''};
          ${Checkers.isValidString( props.builder.builder_header_menu_color ) ? 'border-color: ' + props.builder.builder_header_menu_color + '!important' : ''};
        }
        .Header.Header-Double.Header-Dark svg,
        .Header.Header-Double.Header-Dark .navbar-nav .nav-link{
           color: rgb(255,255,255)!important;
        }
        .Header.Header-Double.Header-Light svg,
        .Header.Header-Double.Header-Light .navbar-nav .nav-link{
          color: rgb(0,0,0)!important;
        }
        .Header.Header-Double.Header-Dark .btn, 
        .Header.Header-Double.Header-Dark .btn:hover:not(.active){
          color: rgb(255,255,255)!important;
          border-color: rgb(255,255,255)!important;
        }
        .Header.Header-Double.Header-Light .btn, 
        .Header.Header-Double.Header-Light .btn:hover:not(.active){
          color: rgb(0,0,0)!important;
          border-color: rgb(0,0,0)!important;
        }
      `}</style>}
      {
        <style jsx global>{`
          .Modal-Header .modal-dialog .modal-content,
          .Modal-Header .modal-dialog .modal-content .Modal__body,
          .Modal-Header .modal-dialog .modal-content .Modal__body ul li ul.dropdown-menu{
            font-weight: ${  props.builder.builder_header_type == 'default' ? 600 :Checkers.valueToBoolean( props.builder.builder_header_menu_bold ) ? 600 : 400  }!important;
            background: ${ Checkers.isValidString( props.builder.builder_header_color ) ? props.builder.builder_header_color : stateForModal ? 'rgba(0,0,0)' : 'rgba(255,255,255)' }!important;
            background-color: ${ Checkers.isValidString( props.builder.builder_header_color ) ? props.builder.builder_header_color : stateForModal ? 'rgba(0,0,0)' : 'rgba(255,255,255)' }!important;
          }
          .Modal-Header .modal-dialog .modal-content .Modal__body .btn,
          .Modal-Header .modal-dialog .modal-content .Modal__body .btn:hover:not(.active){
            font-weight: ${ props.builder.builder_header_type == 'default' ? 600 :Checkers.valueToBoolean( props.builder.builder_header_menu_bold ) ? 600 : 400 }!important;
            ${Checkers.isValidString( props.builder.builder_header_menu_color ) || !!stateForModal ? 'background-color: transparent' : ''};
            color: ${Checkers.isValidString( props.builder.builder_header_menu_color ) ? props.builder.builder_header_menu_color : stateForModal ? 'rgba(255,255,255)' : 'rgba(0,0,0)'}!important;
            border-color: ${Checkers.isValidString( props.builder.builder_header_menu_color ) ? props.builder.builder_header_menu_color : stateForModal ? 'rgba(255,255,255)' : 'rgba(0,0,0)' }!important;
          }
          .Modal-Header-Double .modal-dialog .modal-content,
          .Modal-Header-Double .modal-dialog .modal-content .Modal__body,
          .Modal-Header-Double .modal-dialog .modal-content .Modal__body ul li ul.dropdown-menu{
            background: ${ stateForModal ? 'rgba(0,0,0)' : 'rgba(255,255,255)' }!important;
            background-color: ${ stateForModal ? 'rgba(0,0,0)' : 'rgba(255,255,255)' }!important;
          }
          .Modal-Header .modal-dialog .modal-content .Modal__header a,
          .Modal-Header .modal-dialog .modal-content .Modal__header button,
          .Modal-Header .modal-dialog .modal-content .Modal__header button svg,
          .Modal-Header .modal-dialog .modal-content .Modal__body ul li a,
          .Modal-Header .modal-dialog .modal-content .Modal__body ul li a.nav-link,
          .Modal-Header .modal-dialog .modal-content .Modal__body ul li svg,
          .Modal-Header .modal-dialog .modal-content .Modal__body ul li a svg{
            font-weight: ${ props.builder.builder_header_type == 'default' ? 600 :Checkers.valueToBoolean( props.builder.builder_header_menu_bold ) ? 600 : 400 }!important;
            color: ${ Checkers.isValidString( props.builder.builder_header_menu_color ) ? props.builder.builder_header_menu_color : stateForModal ? 'rgba(255,255,255)' : 'rgba(0,0,0)' }!important;
          }
          .Modal-Header-Double .modal-dialog .modal-content .Modal__header a,
          .Modal-Header-Double .modal-dialog .modal-content .Modal__header button,
          .Modal-Header-Double .modal-dialog .modal-content .Modal__header button svg,
          .Modal-Header-Double .modal-dialog .modal-content .Modal__body ul li a,
          .Modal-Header-Double .modal-dialog .modal-content .Modal__body ul li a.nav-link,
          .Modal-Header-Double .modal-dialog .modal-content .Modal__body ul li svg,
          .Modal-Header-Double .modal-dialog .modal-content .Modal__body ul li a svg{
            color: ${ stateForModal ? 'rgba(255,255,255)' : 'rgba(0,0,0)' }!important;
          }
          .Modal-Header .modal-dialog .modal-content .Modal__body .Contact-Icons ul.Contact-Icons__dropdown{
            box-shadow: 0 0 3px #ccc !important;
          }
        `}</style>
      }
      {props.property &&
        <style>{`
          .Header.Header-Double,
          .Header.Header-Sticky-Top{
            position: initial !important;
          }
        `}</style>
      }
      {
        props.builder.builder_header_type == 'different' ?
          <HeaderTypeOne
              app={app}
              containerClass={containerClass}
              falTimes={falTimes}
              faGripLines={faGripLines}
              designMode={app.designMode}
              headerProps={headerProps}
              logoProps={logoProps}
              navbarModeClass={navbarModeClass}
              resizeClass={resizeClass}
              menu={menu}
              partials={partials}
              showBookButtonMenu={app.showBookButtonMenu}
              contactIcons={contactIcons()}
              referer={referer}
              zIndex={Checkers.objChildExists( props, 'zIndex' ) ? props.zIndex : 0}
              pageTitle={pageTitle}
              headerDiv={headerDiv}
              setHeaderDiv={setHeaderDiv}
          />
          : props.builder.builder_header_type == 'double' ?
            <HeaderTypeTwo
                app={app}
                containerClass={containerClass}
                falTimes={falTimes}
                faGripLines={faGripLines}
                designMode={props.designMode}
                headerProps={app}
                logoProps={logoProps}
                navbarModeClass={navbarModeClass}
                navbarTwoModeClass={navbarTwoModeClass}
                resizeClass={resizeClass}
                menu={menu}
                partials={partials}
                showBookButtonMenu={app.showBookButtonMenu}
                contactIcons={contactIcons()}
                phone={phone}
                property={property}
                referer={referer}
                zIndex={Checkers.objChildExists( props, 'zIndex' ) ? props.zIndex : 0}
                pageTitle={pageTitle}
            />
            : props.builder.builder_header_type == 'minimized' ?
              <HeaderTypeThree
                  app={app}
                  containerClass={containerClass}
                  falTimes={falTimes}
                  faGripLines={faGripLines}
                  designMode={app.designMode}
                  headerProps={headerProps}
                  logoProps={logoProps}
                  navbarModeClass={navbarModeClass}
                  resizeClass={resizeClass}
                  menu={menu}
                  partials={partials}
                  showBookButtonMenu={app.showBookButtonMenu}
                  contactIcons={contactIcons()}
                  referer={referer}
                  zIndex={Checkers.objChildExists( props, 'zIndex' ) ? props.zIndex : 0}
                  pageTitle={pageTitle}
                  setHeaderDiv={setHeaderDiv}
              />
              : props.builder.builder_header_type == 'centered' ?
                <HeaderTypeFour
                    app={app}
                    containerClass={containerClass}
                    falTimes={falTimes}
                    faGripLines={faGripLines}
                    designMode={app.designMode}
                    headerProps={headerProps}
                    logoProps={logoProps}
                    navbarModeClass={navbarModeClass}
                    resizeClass={resizeClass}
                    menu={menu}
                    partials={partials}
                    showBookButtonMenu={app.showBookButtonMenu}
                    contactIcons={contactIcons()}
                    referer={referer}
                    zIndex={Checkers.objChildExists( props, 'zIndex' ) ? props.zIndex : 0}
                    pageTitle={pageTitle}
                    setHeaderDiv={setHeaderDiv}
                />
                :
                <HeaderDefault
                    app={app}
                    containerClass={containerClass}
                    falTimes={falTimes}
                    faGripLines={faGripLines}
                    designMode={app.designMode}
                    headerProps={headerProps}
                    logoProps={logoProps}
                    navbarModeClass={navbarModeClass}
                    resizeClass={resizeClass}
                    menu={menu}
                    partials={partials}
                    showBookButtonMenu={app.showBookButtonMenu}
                    contactIcons={contactIcons()}
                    referer={referer}
                    zIndex={Checkers.objChildExists( props, 'zIndex' ) ? props.zIndex : 0}
                    pageTitle={pageTitle}
                    setHeaderDiv={setHeaderDiv}
                />
      }
    </>
  )
}
