export default function HeaderPartials ( props ) {
  return (
    <>
      {
        props.partials !== null && props.partials.hasOwnProperty( 'book' ) === true ?
        // eslint-disable-next-line react/jsx-key
          props.partials.book != undefined ?
            <li className="nav-item" dangerouslySetInnerHTML={{ __html: props.partials.book.title }}/>
            : ''
          : ''
      }
    </>
  )
}