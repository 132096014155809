import Image from 'next/image';

import Checkers from '../../../helpers/class/Checkers';
import {useEffect} from "react";

export default function HeaderLogo ( props ) {
  let height = 0;
  let factor = 1;

  const logoProps = props.logoProps;
  if ( logoProps.logoMode ) {
    factor = logoProps.logoDarkOriginWidth / logoProps.logoDarkOriginHeight;
    if ( logoProps.logoDarkHeigth != null && logoProps.logoDarkHeigth > 0 ) {
      height = logoProps.logoDarkHeigth;
    } else {
      height = 30;
    }
  } else {
    factor = logoProps.logoLightOriginWidth / logoProps.logoLightOriginHeight;
    if ( logoProps.logoLightHeigth != null && logoProps.logoLightHeigth > 0 ) {
      height = logoProps.logoLightHeigth;
    } else {
      height = 30;
    }
  }

  const logoUrl = logoProps.logoMode ? logoProps.logoDark : logoProps.logoLight;

  const widthInit = height * factor;

  return (
    <>

      {Checkers.isValidString( logoUrl ) ?
        <Image
          loading={'eager'}
          layout={'intrinsic'}
          width={widthInit}
          height={height}
          className="logo"
          src={logoUrl}
          alt="logo_header"
          blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
        />
        :
        <h2 className={logoProps.logoMode ? 'text-white' : 'text-black'}>{props.pageTitle}</h2>
      }
    </>
  );
}
