import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {faMagnifyingGlass as faMagnifyingGlass, faUser as falUser} from '@fortawesome/pro-light-svg-icons';
import { faUserTie as fasUserTie, faPlane as fasPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { position } from 'dom-helpers';
import $ from 'jquery';

import { getSearchDefaultOptionsAction, loadSearchOptionsAction } from '../../../api/client';
import Checkers from '../../../helpers/class/Checkers';
import * as appContext from '../../AppContext';

import { Input } from 'postcss';
import {fetchBuilderSettingByKey} from "../../../helpers/MethodHelper";
import {useRouter} from "next/router";
import {show} from "dom7";
// import {ClickAwayListener} from "@mui/material";

export default function SearchIcon ( props ) {

  const customSearch = useSelector( state => state['propertiesParams']['search']);

  // console.log( "pageId", pageId )

  const pageId = useSelector( state => state['propertiesParams']['page_id']);

  const [ selectedSearchLocation, setSelectedSearchLocation ]= useState( null );

  const toggleTopSearch = function () {
    $( '.search-wrapper' ).fadeToggle();
  }

  const router = useRouter()

  const handleInputChange = ( input ) => {
    setSelectedSearchLocation( input );

    let location_slug = input.slug;
    let location_val = input.value;
    let location_data = location_val.split( "_" );
    let location_type = location_data[0];
    let location_id = location_data[1];
    switch ( location_type ) {
      case 'property':
        if (Checkers.isValidString(location_slug)) {
          toggleTopSearch();
          router.push( location_slug );
        } else {
          toggleTopSearch();
          router.push( "/property/" + location_id );
        }
        break;
      case 'destination':
        if (Checkers.isValidString(location_slug)) {
          toggleTopSearch();
          router.push(location_slug);
        }
        break;
    }
  };

  const [ defaultSearchOptions, setDefaultSearchOptions ] = useState( null );
  useEffect( () => {
    getSearchDefaultOptionsAction( pageId, window.location.pathname ).then( response => {
      setDefaultSearchOptions( response.options );
      if ( response.defaultLocationValue != undefined ) {
        setSelectedSearchLocation( response.defaultLocationValue )
      } else {
        let customOption = {
          value: customSearch,
          label: customSearch
        }
        if ( customOption.value!=null ) {
          setSelectedSearchLocation(customOption)
        }
      }
    });
  }, [pageId]);

  const loadSearchOptions = ( inputValue, callback ) => {
    loadSearchOptionsAction( pageId, inputValue, callback )
  }

  const topSearch = ( searchLocation ) => {
    let location_val = searchLocation !=null ? searchLocation.value : searchLocation;
    let location_data = location_val !=null ? location_val.split( "_" ) : null;
    let location_type = location_data != null ? location_data[0] : null;

    switch ( location_type ) {
    case 'destination':
      location.href = window.location.origin + searchLocation.slug;
      break;
    case 'location':
      location.href = window.location.origin + searchLocation.slug;
      break;
    case 'property':
      location.href = window.location.origin + searchLocation.slug;
      break;
    default:
      location.href = window.location.origin + "/properties/search/?search=" + location_val;
      break;
    }
  }

  const topSearchHandler = ( event ) => {
    event.preventDefault();
    topSearch( selectedSearchLocation )
  };

  // useEffect( () => {
  //   const keyEnter = event => {
  //     if ( event.key === 'Enter' ) {
  //       topSearch( selectedSearchLocation )
  //       // console.log( "enter pressed" )
  //       event.preventDefault()
  //     }
  //   }
  //
  //   document.addEventListener( 'keypress', keyEnter )
  // }, [selectedSearchLocation])

  // const [state, setState] = useState(false)

  const showGuestAreaLink = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_menu_guests_area_show') == 1
  );

  const guestAreaLinkTitle = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_menu_guests_area_link_title')
  );

  const showOwnerAreaLink = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_menu_owners_area_show') == 1
  );

  const ownerAreaLinkTitle = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_menu_owners_area_link_title')
  );

  return (
    <>
      {
          (showGuestAreaLink || showOwnerAreaLink) ?
              <>
                <li
                    className={'cursor-pointer nav-item me-0 ms-0 position-relative'}
                >
                  {
                    <>
                      <a id={'links-icon'}
                         href={'#/'}
                         className="dropdown-toggle d-flex align-items-center" aria-label="links" title="links"
                         data-bs-toggle="dropdown"
                      >
                        <span className="sr-only">links</span>
                        <FontAwesomeIcon icon={falUser} size="2x"/>
                      </a>
                      <ul className={"dropdown-menu "} style={{
                        transform: 'translate(-50%, 0)',
                        left: '50%'
                      }}>
                        {
                          showOwnerAreaLink ?
                              <>
                                <li className={'menu-icon'} >
                                  <a href={"https://owners.loggia.gr/en/" + pageId + "/login"} target={"_blank"} rel="noreferrer">
                                    <FontAwesomeIcon icon={fasUserTie}/>&nbsp;
                                    {
                                      Checkers.isValidString(guestAreaLinkTitle) ?
                                          <>{guestAreaLinkTitle}</>
                                          : <>{"Owner's area"}</>
                                    }
                                  </a>
                                </li>
                              </>
                              : <></>
                        }
                        {
                          showGuestAreaLink ?
                              <>
                                <li className={'menu-icon'} >
                                  <a href={"https://guests.loggia.gr/en/" + pageId + "/login"} target={"_blank"} rel="noreferrer">
                                    <FontAwesomeIcon icon={fasPlane}/>&nbsp;
                                    {
                                      Checkers.isValidString(guestAreaLinkTitle) ?
                                          <>{guestAreaLinkTitle}</>
                                          : <>{"Traveller's area"}</>
                                    }
                                  </a>
                                </li>
                              </>
                              : <></>
                        }
                      </ul>
                    </>
                  }
                </li>
              </>
              : <></>
      }
      <li className={"nav-item me-0"}>
        <form id="quick_search" onSubmit={event => topSearchHandler(event)}>
          <div className={"search-wrapper"}>
            <div className={"search-container"}>
              {/*<input className="search expandright" id="searchright" type="search" name="q" placeholder="Search" />*/}


              <AsyncCreatableSelect
                  noOptionsMessage={() => 'nothing found'}
                  loadingMessage={() => 'searching...'}
                  cacheOptions={false}
                  defaultOptions={defaultSearchOptions}
                  loadOptions={loadSearchOptions}
                  onChange={handleInputChange}
                  // // defaultValue={defaultLocationValue}
                  value={selectedSearchLocation}
                  className="SearchBar-Form__form-group-select"
                  id="location_property"
                  name="location_property"
                  inputId="searchright"
                  instanceId="location_property"
                  isSearchable
                  formatCreateLabel={(inputValue: string) => {
                    return ('Search for ' + inputValue)
                  }}
                  options={props.options}
                  // styles={props.customStyles}
                placeholder={'Where are you going'}
                blurInputOnSelect
                menuPlacement={'bottom'}
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              />

              {/*<span>*/}
              <button
                className={"search-btn pull-right"}
                form="quick_search"
                onClick={event => topSearchHandler( event )}
                aria-label="Search"
                type="submit">
                <FontAwesomeIcon size="2x" icon={faMagnifyingGlass} />
              </button>
              {/*</span>*/}
            </div>
            <div className={"search-backdrop"} onClick={toggleTopSearch} />
          </div>
          <label className="button searchbutton nav-link d-flex align-items-center search-label"
                 onClick={toggleTopSearch} htmlFor="searchright">
            <span className="sr-only">Search</span>
            <FontAwesomeIcon size="2x" icon={faMagnifyingGlass}/>
          </label>
        </form>
      </li>
    </>
  )
}
