import React from 'react';

import FooterWithApps from './footer/types/FooterWithApps';
import FooterWithoutApps from './footer/types/FooterWithoutApps';

interface FooterProps {
  designMode: any
  footerContent: any
  iosApp: any
  androidApp: any
  iosAppText: any
  androidAppText: any
  hasLoggiaBadge: any
  loggiaBadgeText: any
  registrationNumber: any
  registrationNumberText: any
  title: any
  menu: any
  fixed: any
  isDesktop: any
  footerPaddingClass?: string
}

export default function Footer ( props : FooterProps ) {
  let footerClass = null;

  if ( props.designMode != null ) {
    if ( props.designMode )
      footerClass = 'dark-mode'
    else
      footerClass = 'light-mode'
  } else {
    footerClass = 'light-mode'
  }

  if ( props.footerContent.content != undefined ) {
    return (
      <>
        <footer className={'bg-design-color xs-p-b-85 ' + (props.footerPaddingClass!=undefined?props.footerPaddingClass:'')} dangerouslySetInnerHTML={{ __html: props.footerContent.content.app_section_content }}/>
      </>
    );
  } else
    return (
      props.iosApp != null || props.androidApp != null ?
        <FooterWithApps
          iosApp={props.iosApp}
          androidApp={props.androidApp}
          iosAppText={props.iosAppText}
          androidAppText={props.androidAppText}
          hasLoggiaBadge={props.hasLoggiaBadge}
          loggiaBadgeText={props.loggiaBadgeText}
          registrationNumber={props.registrationNumber}
          registrationNumberText={props.registrationNumberText}
          footerPaddingClass={props.footerPaddingClass}
          title={props.title}
          footerClass={footerClass}
          menu={props.menu}
        />
        :
        <FooterWithoutApps footerPaddingClass={props.footerPaddingClass} footerClass={footerClass} menu={props.menu}/>
    )
}
