import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';

interface IProps {
  modalName: string
  className: string
  modalSize?: 'sm' | 'lg' | 'xl'
  closeModal: () => void
  showModal: boolean
  setShowModal: (val: boolean) => void
  children: ReactNode
  modalHeaderClass?: string | null,
  falTimes?: any | null,
  modalBodyClass?: string | null
}

const CustomModal: FC<IProps> = ({ modalName, className, modalSize, closeModal, showModal, setShowModal, children, modalHeaderClass, falTimes, modalBodyClass }) => {
  // const [ showModal, setShowModal ] = useState<boolean>( show || false );
  const router = useRouter();
  const hasHash = router.asPath.includes( '#' )

  const openHandler = () => {
    if ( !hasHash ) {
      router.push({
        hash: modalName + ''
      }, undefined, { scroll: false })
    }
    setShowModal( true );
  }

  const closeHandler = () => {
    closeModal();
    setShowModal( false );
    if ( showModal ) {
      router.back()
    }
  }
  const clickOutSideHandler = () => {
    closeModal();
    setShowModal( false );
  }


  useEffect( () => {
    if ( showModal ) openHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  useEffect( () => {
    if ( !hasHash && showModal ) clickOutSideHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasHash]);


  return (
    <Modal
        id={'modal-menu'}
      className={className}
      size={modalSize}
      show={showModal}
      onHide={closeHandler}
    >
      <Modal.Header className={`Modal__header ${modalHeaderClass}`}>
        <button type="button" className="btn-close btn-no-outline background-none w-auto h-auto" aria-label="Close" onClick={closeHandler}>
          <FontAwesomeIcon icon={falTimes} className="text-black" style={{ fontSize: '16px' }}/>
        </button>
      </Modal.Header>
      <Modal.Body className={modalBodyClass}>
        {children}
      </Modal.Body>
    </Modal>
  )
}

export default memo( CustomModal );
