import Checkers from './class/Checkers';
import {useEffect, useState} from "react";
import {setBedrooms} from "../redux/actions";
import {useSelector} from "react-redux";

export const hexToRgb = ( hex ) => {
  let c;
  if ( /^#([A-Fa-f0-9]{3}){1,2}$/.test( hex ) ) {
    c= hex.substring( 1 ).split( '' );
    if ( c.length== 3 ) {
      c= [ c[0], c[0], c[1], c[1], c[2], c[2] ];
    }
    c= '0x'+c.join( '' );
    return [ ( c>>16 )&255, ( c>>8 )&255, c&255 ].join( ',' );
  }
  return 0;
}

export const initManifest = ( head, app, start_url ) => {

  const title = Checkers.objChildExists( head, 'data' ) && Checkers.objChildExists( head.data, 'title' ) ? head.data.title : '';
  const motto = Checkers.objChildExists( head, 'data' ) && Checkers.objChildExists( head.data, 'motto' ) ? head.data.motto : '';
  const designColor = Checkers.objChildExists( app, 'designColor' ) ? app.designColor : '';

  if ( Object.keys( head ).length > 0 ) {
    return {
      "$schema": "https://json.schemastore.org/web-manifest-combined.json",
      "allOf": [
        { "$ref": "https://json.schemastore.org/web-manifest.json" },
        { "$ref": "https://json.schemastore.org/web-manifest-app-info.json" },
        { "$ref": "https://json.schemastore.org/web-manifest-share-target.json" }
      ],
      "id": "/?source=pwa",
      "name":  `${title}`,
      "short_name": `${title}`,
      "start_url": `${start_url}`,
      "display": "standalone",
      "background_color": "#fafafa",
      "orientation": "any",
      "theme_color": `${designColor}`,
      "description": `${motto}`,
      "icons": []
    };
  }
}

export const extendManifest = ( manifest, head? ) => {

  const icon_60 = Checkers.objChildExists( head, 'data' ) && Checkers.objChildExists( head.data, 'icon_60' ) ? head.data.icon_60 : '';
  const icon_76 = Checkers.objChildExists( head, 'data' ) && Checkers.objChildExists( head.data, 'icon_76' ) ? head.data.icon_76 : '';
  const icon_120 = Checkers.objChildExists( head, 'data' ) && Checkers.objChildExists( head.data, 'icon_120' ) ? head.data.icon_120 : '';
  const icon_152 = Checkers.objChildExists( head, 'data' ) && Checkers.objChildExists( head.data, 'icon_152' ) ? head.data.icon_152 : '';
  const icon_256 = Checkers.objChildExists( head, 'data' ) && Checkers.objChildExists( head.data, 'icon_256' ) ? head.data.icon_256 : '';
  const icon = Checkers.objChildExists( head, 'data' ) && Checkers.objChildExists( head.data, 'icon' ) ? head.data.icon : '';

  if ( icon_60 != null && icon_60 != '' ) {
    manifest.icons.push(
      {
        "src": `${icon_60}`,
        "sizes": "60x60",
        "type": "image/png",
        "purpose": "maskable"
      }
    );

  }
  if ( icon_76 != null && icon_76 != '' ) {
    manifest.icons.push(
      {
        "src": `${icon_76}`,
        "sizes": "76x76",
        "type": "image/png",
        "purpose": "maskable"
      }
    );

  }
  if ( icon_120 != null && icon_120 != '' ) {
    manifest.icons.push(
      {
        "src": `${icon_120}`,
        "sizes": "120x120",
        "type": "image/png",
        "purpose": "maskable"
      }
    );

  }
  if ( icon_152 != null && icon_152 != '' ) {
    manifest.icons.push(
      {
        "src": `${icon_152}`,
        "sizes": "152x152",
        "type": "image/png",
        "purpose": "maskable"
      }
    );

  }
  if ( icon_256 != null && icon_256 != '' ) {
    manifest.icons.push(
      // {
      // 	"src": `${icon_256}`,
      // 	"sizes": "192x192",
      // 	"type": "image/png",
      // 	"purpose": "maskable"
      // },
      {
        "src": `${icon_256}`,
        "sizes": "256x256",
        "type": "image/png",
        "purpose": "any"
      }
    );

  }
  if ( icon != null && icon != '' ) {
    manifest.icons.push(
      {
        "src": `${icon}`,
        "sizes": "1024x1024",
        "type": "image/png",
        "purpose": "maskable"
      }
    );
  }

  return manifest;
}


export const fetchBuilderSettingByKey = (builderSettingsData : any, key: string, defaultValue = null) => {
    if ( builderSettingsData==null || builderSettingsData==undefined ) {
        return defaultValue;
    }
    if ( builderSettingsData['settings']==null || builderSettingsData['settings']==undefined ) {
        return defaultValue;
    }

    if(builderSettingsData['settings'][key]!=undefined){
        return builderSettingsData['settings'][key]
    }

    return builderSettingsData['settings']['builder'][key]
}

export const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // only execute all the code below in client side
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


export const randomCoordinateFactor = () => {
    return Math.random()/100
}

export const textColor = (darkMode) => {
    if (darkMode === true || darkMode === "1") {
        return "text-light-color"
    }

    return '';
}

export const btnTextColor = (darkMode) => {
    if (darkMode === true || darkMode === "1") {
        return "text-white"
    }

    return 'text-black';
}

export const isDarkMode = (darkMode) => {
    if (darkMode === true || darkMode === "1") {
        return true
    }

    return false;
}

export const updateBedrooms = (value, setterFunction) => {
    if (value.hasOwnProperty('value')) {
        if(value.value=='all'){
            setterFunction(0)
        } else {
            setterFunction(value.value)
        }

        console.log("newValue", value.value)
    }
}

export const getBedroomValue = (bedrooms, bedroomOptions) => {
    let value = null;

    bedroomOptions.forEach( (item) => {
        if(item.value == bedrooms){
            value = item
        }
    })

    return value
}

export const getUnavailablePropertiesOpacity = (availabilityStatus, unavailablePropertiesOpacity) => {
    return availabilityStatus==0 && Checkers.isValidNumber(unavailablePropertiesOpacity) ? unavailablePropertiesOpacity : 1
}

export const getPropertyViewType = (availabilityStatus, propertiesViewType, unavailabelPropertiesViewType) => {
    if(availabilityStatus==null){
        return propertiesViewType
    }

    if(availabilityStatus==1){
        return propertiesViewType
    }

    if(availabilityStatus==0){
        return unavailabelPropertiesViewType
    }

    return propertiesViewType
}

export const enableBookingBtn = (propertyInitialAvailability, priceLoading, personsForBooking, dateRangeValid) => {
    return (propertyInitialAvailability || priceLoading) && Checkers.isValidPositiveNumber(personsForBooking) && dateRangeValid
}

export const getHomepageUrl = (defaultUrl, useRedirectUrl, homePageUrl) => {
    if(useRedirectUrl && Checkers.isValidString(homePageUrl)){
        return (Checkers.isValidString(process.env.SCHEME) ? process.env.SCHEME : "https://") + homePageUrl;
    }

    return defaultUrl
}