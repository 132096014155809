import { useEffect, useState } from 'react';

import Checkers from '../../../helpers/class/Checkers';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../helpers/MethodHelper";

export default function FooterMenu ( props ) {

  const showMenuItems = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_show_menu_items')
  );

  const [ referer, setReferer ] = useState( null );

  useEffect( () => {

    if ( window !== undefined ) {
      setReferer( window.location.origin + '/' );
    }

  }, [referer]);

  return (
      <>
        {
          showMenuItems == '1' ?
              <>
                <ul className={'d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-lg-start no-style no-padding text-nowrap font-arial fs-11'}>
                  {
                      Checkers.isValidArray(props.menu) &&
                      props.menu.map((menu, key) => {
                        let loggiaUrl = menu.loggiaUrl;
                        if (Checkers.isValidString(loggiaUrl) && loggiaUrl.startsWith('/')) {
                          loggiaUrl = loggiaUrl.substring(1);
                        }
                        return (
                            menu.title != null ?
                                // eslint-disable-next-line react/jsx-key
                                <li className={'inline'} key={key}>
                                  <a
                                      href={loggiaUrl != null ? referer + loggiaUrl : menu.urlSlug != null ? referer + menu.urlSlug : '#'}
                                      className={props.hasOwnProperty('apps') ? props.apps == true ? 'p-l-10 p-r-10 opacity-60' : 'p-l-10 p-r-10' : 'p-l-10 p-r-10'}>
                                    {menu.title}
                                  </a>
                                </li>
                                : ''
                        )
                      })
                  }
                </ul>
              </>
              :
              <><br /></>
        }
      </>
  )
}
