import {useEffect, useState} from 'react';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Checkers from '../../../helpers/class/Checkers';
import HeaderLogo from '../logo/HeaderLogo';
import HeaderLogoAltMode from '../logo/HeaderLogoAltMode';
import HeaderNavigation from '../menu/HeaderNavigation';
import ContactIcons from '../partials/ContactIcons';
import HeaderCustomModal from '../partials/HeaderCustomModal';
import {fetchBuilderSettingByKey, getHomepageUrl} from "../../../helpers/MethodHelper";
import {useSelector} from "react-redux";
import Link from "next/link";

export default function HeaderTypeFour ( props ) {

  // useEffect(() =>{
  //   console.log("props.apps", props.apps)
  // })

  const [ show, setShow ] = useState( false );
  const handleShow = () => {
    setShow( true );
  }
  const handleHide = () => {
    setShow( false );
  }

  const useRedirectUrl = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'useRedirectUrl')
  );

  const homePageUrl = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'logoUrl')
  );

  const homepageUrl = Checkers.isValidString(getHomepageUrl(props.referer, useRedirectUrl, homePageUrl)) ? getHomepageUrl(props.referer, useRedirectUrl, homePageUrl) : "#"

  return (
    <>
      <header ref={props.setHeaderDiv} className={`header-centered Header Header-Centered ${props.navbarModeClass} flex-row h-auto`}
        data-pages={props.resizeClass != '' ? 'header' : ''}
        data-builder-header="centered"
        data-pages-header={props.resizeClass != '' ? 'autoresize' : ''}
        data-pages-resize-class={props.resizeClass}
        style={{ zIndex: props.zIndex > 0 ? props.zIndex : 10 }}
      >
        <div className={Checkers.isValidString( props.containerClass ) ? props.containerClass : 'container'}>
          <div className={'row align-items-center'}>
            <ul className="navbar-nav flex-row col-5 justify-content-start invisible h-0" data-builder-left-nav>
              <HeaderNavigation
                referer={props.referer}
                menu={props.menu}
                partials={props.partials}
                headerPartials={false}
                headerPartialBook={false}
                modalDropdownClass={null}
                handleHide={handleHide}
              />
            </ul>

            <div className="nav flex-row col-12 col-md-2 justify-content-center" data-builder-center-logo>
              <button type="button" className="navbar-toggle collapsed border-0 hidden me-auto ms-4" aria-expanded="false" onClick={handleShow}>
                <span className="sr-only">Toggle navigation</span>
                <FontAwesomeIcon icon={props.faGripLines} size="2x"/>
              </button>
              <Link href={homepageUrl}>
                <a className="navbar-brand py-1 px-0" title={'logo'}>
                  {
                    props.bgTransparent == true ?
                        <HeaderLogoAltMode
                            app={props.app}
                            navbarModeClass={props.navbarModeClass}
                            resizeClass={props.resizeClass}
                            logoProps={props.logoProps}
                            pageTitle={props.pageTitle}
                        />
                        :
                        <HeaderLogo
                            navbarModeClass={props.navbarModeClass}
                            resizeClass={props.resizeClass}
                            logoProps={props.logoProps}
                            pageTitle={props.pageTitle}
                        />
                  }
                </a>
              </Link>
            </div>

            <ul className="navbar-nav flex-row col-5 justify-content-end invisible h-0" data-builder-right-nav>
              <HeaderNavigation
                referer={props.referer}
                menu={null}
                partials={props.partials}
                headerPartialBook={true}
                modalDropdownClass={null}
                contactIcons={null}
                handleHide={handleHide}
              />
              {
                props.hasOwnProperty( 'contactIcons' ) ?
                  props.contactIcons != null ?
                    <ContactIcons contactIcons={props.contactIcons}/>
                    : ''
                  : ''
              }
            </ul>
          </div>

        </div>
      </header>
      <HeaderCustomModal
        modalName={'side_menu'}
        className="Modal-Header slide-left"
        modalSize="lg"
        closeModal={handleHide}
        showModal={show}
        setShowModal={setShow}
        modalBodyClass="Modal__body navbar-menu d-flex flex-column justify-content-between padding-0"
        falTimes={props.falTimes}
      >
        <ul className="navbar-nav justify-content-start">
          <HeaderNavigation
            referer={props.referer}
            menu={props.menu}
            partials={props.partials}
            headerPartialBook={false}
            modalDropdownClass={'shadow-none position-relative mt-1 ps-2 pe-2 w-100'}
            contactIcons={null}
            menuItemDisableWrap={true}
            handleHide={handleHide}
          />
        </ul>
        <ul className="navbar-nav justify-content-start mb-5">
          <HeaderNavigation
            referer={props.referer}
            menu={null}
            partials={props.partials}
            contactIcons={null}
            menuItemDisableWrap={true}
            handleHide={handleHide}
          />
          {
            props.hasOwnProperty( 'contactIcons' ) && props.contactIcons != null &&
              <ul className="Contact-Icons mt-1 mb-1 ms-0 d-xs-flex d-sm-flex flex-row" style={{ margin: '5px 25px' }}>
                <ContactIcons contactIcons={props.contactIcons} modalDropdownClass={'shadow position-fixed mb-3 ps-2 pe-2 w-auto'} side="left"/>
              </ul>
          }
        </ul>
      </HeaderCustomModal>
    </>
  );
}
