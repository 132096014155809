import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function ContactSkeleton ( props ) {
    return (
        <>
            <div className="container">
                <div className="w-100">
                    <Skeleton height={42} className="col-12 col-sm-8 b-rad-md mb-1"/>
                    <Skeleton height={195} width={250} className="b-rad-md mb-1"/>
                </div>
                {
                    props.showContactForm &&
                    <>
                        <div className="w-100">
                            <div className={'container'}>
                                <Skeleton height={25} className="w-100 b-rad-md mb-2"/>
                                {/*<div className="row">*/}
                                <div className="col-6">
                                    <Skeleton height={50} className="w-100 b-rad-md mb-1"/>
                                </div>
                                <div className="col-6">
                                    <Skeleton height={50} className="w-100 b-rad-md mb-1"/>
                                </div>
                                {/*</div>*/}
                                <Skeleton height={50} className="w-100 b-rad-md mb-1"/>
                                <Skeleton height={150} className="w-100 b-rad-md mb-1"/>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}
