import * as React from 'react';
import {useEffect, useState} from 'react';
import { faPhone as fasPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Checkers from '../../../helpers/class/Checkers';
import HeaderLogo from '../logo/HeaderLogo';
import HeaderNavigation from '../menu/HeaderNavigation';
import ContactIcons from '../partials/ContactIcons';
import HeaderCustomModal from '../partials/HeaderCustomModal';
import {fetchBuilderSettingByKey, getHomepageUrl} from "../../../helpers/MethodHelper";
import {useSelector} from "react-redux";
import Link from "next/link";

export default function HeaderTypeTwo ( props ) {

  const [ show, setShow ] = useState( false );
  const handleShow = () => {
    setShow( true );
  }
  const handleHide = () => {
    setShow( false );
  }

  const ul_class = props.property ? 'nav inner navbar-nav my-2 d-flex flex-row justify-content-end align-items-center my-md-0'
    : 'nav inner navbar-nav my-2 d-flex flex-row justify-content-end align-items-center my-md-0 hidden-xs hidden-sm hidden';
  const li_class = props.property ? '\ hidden-xs hidden-sm' : '';

  props.logoProps.logoMode = props.navbarModeClass.match( 'Header-Dark' );

  const useRedirectUrl = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'useRedirectUrl')
  );

  const homePageUrl = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'logoUrl')
  );

  const homepageUrl = Checkers.isValidString(getHomepageUrl(props.referer, useRedirectUrl, homePageUrl)) ? getHomepageUrl(props.referer, useRedirectUrl, homePageUrl) : "#"

  return (
    <>
      <header
        className={`header-one Header Header-Double ${props.navbarModeClass} px-3 py-2 border-bottom`}
        data-builder-header="double" data-builder-property={props.property}
      >
        <div className={Checkers.isValidString( props.containerClass ) ? props.containerClass : 'container'}>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <Link href={homepageUrl}>
              <a  className="d-flex align-items-center my-1 text-white text-decoration-none" title={'logo'}>
                <HeaderLogo
                    navbarModeClass={props.navbarModeClass}
                    resizeClass={props.resizeClass}
                    logoProps={props.logoProps}
                    pageTitle={props.pageTitle}
                />
              </a>
            </Link>
            <div id="navbar-toggle" className="d-flex flex-row justify-content-end align-items-center">
              <button type="button" className="navbar-toggle border-0 visible-xs visible-sm" aria-expanded="false" onClick={handleShow}>
                <span className="sr-only">Toggle navigation</span>
                <FontAwesomeIcon icon={props.faGripLines} size="2x"/>
              </button>
            </div>
            <ul className={ul_class}>
              <li>
                <a className={`hint-text text-capitalize ${li_class}`} href="https://owners.loggia.gr/en/12/login" target="_blank" rel="noreferrer">Owner&apos;s Login</a>
              </li>
              <li>
                <a className={`hint-text text-capitalize b-r p-r-20 ${li_class}`} href="https://guests.loggia.gr/en/12/login" target="_blank" rel="noreferrer">Traveler&apos;s Login</a>
              </li>
              <li>
                <a href={`tel:${props.phone}`} className={`font-montserrat fs-13 ${li_class}`}>{props.phone} <FontAwesomeIcon icon={fasPhone} className="m-l-10"/></a>
              </li>
              <li className="hidden">
                <a href={`tel:${props.phone}`} className="font-montserrat fs-13"><FontAwesomeIcon icon={fasPhone} className="m-l-10 fs-18"/></a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <header className={`header-two Header ${props.navbarTwoModeClass} Header-Custom-Color Header-Sticky-Top flex-nowrap px-0 py-2 invisible hidden-xs hidden-sm h-0 padding-0`} style={{ zIndex: props.zIndex > 0 ? props.zIndex : 10 }}>
        <div className={ `${Checkers.isValidString( props.containerClass ) ? props.containerClass : 'container'} d-flex flex-row justify-content-center align-items-center`}>
          <ul className="navbar-nav flex-row col-lg-auto me-lg-auto">
            <HeaderNavigation
              referer={props.referer}
              menu={props.menu}
              partials={props.partials}
              headerPartials={false}
              headerPartialBook={false}
              modalDropdownClass={null}
              handleHide={handleHide}
            />
          </ul>
          <ul className="navbar-nav flex-row justify-content-end">
            <HeaderNavigation
              referer={props.referer}
              menu={null}
              partials={props.partials}
              headerPartialBook={true}
              contactIcons={null}
              handleHide={handleHide}
            />
            {
              props.hasOwnProperty( 'contactIcons' ) && props.contactIcons != null &&
              <ul className="mt-1 mb-1 ms-0 d-xs-flex d-sm-flex flex-row Contact-Icons" style={{ margin: '5px 25px' }}>
                <ContactIcons contactIcons={props.contactIcons}/>
              </ul>
            }
          </ul>
        </div>
      </header>
      <HeaderCustomModal
        modalName={'side_menu'}
        className="Modal-Header Modal-Header-Double slide-right"
        modalSize="lg"
        closeModal={handleHide}
        showModal={show}
        setShowModal={setShow}
        modalBodyClass="Modal__body navbar-menu d-flex flex-column justify-content-between padding-0"
        falTimes={props.falTimes}
      >
        <ul className="navbar-nav justify-content-start">
          <HeaderNavigation
            referer={props.referer}
            menu={props.menu}
            partials={props.partials}
            headerPartialBook={false}
            modalDropdownClass={'shadow-none position-relative mt-1 ps-2 pe-2 w-100'}
            contactIcons={null}
            menuItemDisableWrap={true}
            handleHide={handleHide}
          />
        </ul>
        <ul className="navbar-nav justify-content-start mb-5">
          <HeaderNavigation
            referer={props.referer}
            menu={props.menu}
            partials={props.partials}
            modalPartials={true}
            contactIcons={null}
            menuItemDisableWrap={true}
            handleHide={handleHide}
          />
          {
            props.hasOwnProperty( 'contactIcons' ) && props.contactIcons != null &&
            <ul className="Contact-Icons mt-1 mb-1 ms-0 d-xs-flex d-sm-flex flex-row" style={{ margin: '5px 25px' }}>
              <ContactIcons contactIcons={props.contactIcons} modalDropdownClass={'shadow position-fixed mb-3 ps-2 pe-2 w-auto'}/>
            </ul>
          }
        </ul>
      </HeaderCustomModal>
    </>
  );
}
